import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { AiOutlineSearch } from 'react-icons/ai';
import { Box, Input } from '@chakra-ui/react';

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, type = '' }) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = useState(globalFilter);

	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<Box bg="white" borderBottom="2px solid #eeeeee" borderRadius="10px 10px 0 0" p={2}>
			{type === 'Survey' || type === 'Calculator' ? (
				<Box color="red.500" flexBasis="100%">
					※如欲獲得期間內包含相關字詞之資料，請先選擇日期期間再按搜尋，再接著輸入字詞即可獲得結果，不須再按搜尋。
				</Box>
			) : null}
			<Box alignItems="center" display="flex" py={2} position="relative">
				<Input
					border="1px solid #eeeeee"
					placeholder={`共有 ${count} 筆記錄`}
					type="keywords"
					value={value || ''}
					onChange={(e) => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
				/>
				<Box
					as={AiOutlineSearch}
					bg="transparent"
					color="gray.300"
					p="10px"
					position="absolute"
					right={2}
					size="40px"
				/>
			</Box>
		</Box>
	);
};

import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getStaffList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/staffs', config)
}

export const showStaff = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/staffs/' + id, config)
}

export const createStaff = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.post(REACT_APP_API_URL + '/staffs', data, config)
}

export const updateStaff = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.put(REACT_APP_API_URL + '/staffs/' + id, data, config)
}

export const deleteStaff = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.delete(REACT_APP_API_URL + '/staffs/' + id, config)
}

import Axios from 'axios';
import { getToken } from 'utils/auth';

export const getCalculatorNeedlelessSMPList = () => {
	const { REACT_APP_API_URL } = process.env;
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	};

	return Axios.get(REACT_APP_API_URL + '/embroideryList', config);
};

import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import Nav from 'layout/Nav/Nav'
import { Sidebar } from 'layout/Sidebar/Sidebar'
import { Main } from 'layout/Main/Main'
import Router from 'router/Router'

const StyledWrapper = styled(Box)`
  height: 100vh;
  overflow: hidden;
  width: 100vw;
`

const StyledContent = styled(Box)`
  padding: 20px 10px;
`
export const Dashboard = (props) => {
  return (
    <StyledWrapper>
      <Sidebar />
      <Main>
        <Nav />
        <StyledContent>
          <Router />
        </StyledContent>
      </Main>
    </StyledWrapper>
  )
}

export default function getMinMax(array, key) {
	let min = 0;
	let max = 0;

	array.forEach((row) => {
		min = Math.min(row.values[key], min);
		max = Math.max(row.values[key], max);
	});

	return [min, max];
}

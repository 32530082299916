import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getKnowledgePostList } from 'api/Knowledge';
import moment from 'moment';
import { hairKnowledgeCategory } from './hairKnowledgeCategory';

export const HairKnowledgePosts = () => {
	const [data, setData] = useState([]);
	const [type] = useState('Knowledge-Post');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'no',
		},
		{
			Header: '類別',
			accessor: 'type',
			Cell: ({ cell: { value } }) => {
				return hairKnowledgeCategory.filter((item) => item.type === value).map((data) => data.name);
			},
		},
		{
			Header: '文章標題',
			accessor: 'title',
		},
		{
			Header: '發布日期',
			accessor: 'created_at',
			Cell: ({ cell: { value } }) => {
				return moment(value).format('YYYY-MM-DD');
			},
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getKnowledgePostList()
			.then(function (res) {
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
				<Button as={Link} to="post/add" ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					新增文章
				</Button>
			</Flex>
			<Table />
		</Provider>
	);
};

import React, { useEffect, useState } from 'react'
import JoditEditor from 'jodit-react'
import styled from 'styled-components'

const StyledEditor = styled.div`
	a {
		color: #aaaaaa;
		text-decoration: underline;
	}

	.jodit-status-bar-link {
		display: none;
	}

	.jodit-source * {
		line-height: 1.3;
		font-size: 14px;
	}

	.jodit-container {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.jodit-workplace div,
	.jodit-workplace span,
	.jodit-workplace applet,
	.jodit-workplace object,
	.jodit-workplace iframe,
	.jodit-workplace h1,
	.jodit-workplace h2,
	.jodit-workplace h3,
	.jodit-workplace h4,
	.jodit-workplace h5,
	.jodit-workplace h6,
	.jodit-workplace p,
	.jodit-workplace blockquote,
	.jodit-workplace pre,
	.jodit-workplace a,
	.jodit-workplace abbr,
	.jodit-workplace acronym,
	.jodit-workplace address,
	.jodit-workplace big,
	.jodit-workplace cite,
	.jodit-workplace code,
	.jodit-workplace del,
	.jodit-workplace dfn,
	.jodit-workplace em,
	.jodit-workplace img,
	.jodit-workplace ins,
	.jodit-workplace kbd,
	.jodit-workplace q,
	.jodit-workplace s,
	.jodit-workplace samp,
	.jodit-workplace small,
	.jodit-workplace strike,
	.jodit-workplace strong,
	.jodit-workplace sub,
	.jodit-workplace sup,
	.jodit-workplace tt,
	.jodit-workplace var,
	.jodit-workplace b,
	.jodit-workplace u,
	.jodit-workplace i,
	.jodit-workplace center,
	.jodit-workplace dl,
	.jodit-workplace dt,
	.jodit-workplace dd,
	.jodit-workplace ol,
	.jodit-workplace ul,
	.jodit-workplace li,
	.jodit-workplace fieldset,
	.jodit-workplace form,
	.jodit-workplace label,
	.jodit-workplace legend,
	.jodit-workplace table,
	.jodit-workplace caption,
	.jodit-workplace tbody,
	.jodit-workplace tfoot,
	.jodit-workplace thead,
	.jodit-workplace tr,
	.jodit-workplace th,
	.jodit-workplace td,
	.jodit-workplace article,
	.jodit-workplace aside,
	.jodit-workplace canvas,
	.jodit-workplace details,
	.jodit-workplace embed,
	.jodit-workplace figure,
	.jodit-workplace figcaption,
	.jodit-workplace footer,
	.jodit-workplace header,
	.jodit-workplace hgroup,
	.jodit-workplace menu,
	.jodit-workplace nav,
	.jodit-workplace output,
	.jodit-workplace ruby,
	.jodit-workplace section,
	.jodit-workplace summary,
	.jodit-workplace time,
	.jodit-workplace mark,
	.jodit-workplace audio,
	.jodit-workplace video {
		border: 0;
		font-size: 100%;
		font: inherit;
		margin: 0;
		padding: 0;
		vertical-align: baseline;
	}

	.jodit-workplace p {
		font-size: 1rem;
		line-height: 1.875;
		letter-spacing: 0.1rem;
		color: #767678;
		margin-bottom: 1.875rem;
	}

	.jodit-workplace img {
		max-width: 100%;
	}

	.jodit-workplace blockquote {
		color: #cbcbcb;
		font-size: 1.25rem;
		font-weight: bold;
		margin: 0.25rem 0 1.25rem;
	}

	@media (max-width: 600px) {
		.jodit-workplace blockquote {
			font-size: 1rem;
		}
	}

	.jodit-workplace strong {
		font-weight: normal;
	}

	.jodit-workplace em {
		font-style: italic;
	}

	.jodit-workplace a {
		color: #007bff;
		text-decoration: none;
		background-color: transparent;
	}

	.jodit-workplace a:hover {
		color: #0056b3;
		text-decoration: underline;
	}

	@media (max-width: 1200px) {
		.jodit-workplace {
			font-size: 12px;
		}
	}

	@media (max-width: 1440px) {
		.jodit-workplace {
			font-size: 14px;
		}
	}

	.jodit-workplace {
		color: #4a4a4a;
		font-family: 'PingFangTC-Regular', 'Noto Sans TC', 'Microsoft JhengHei';
		font-size: 16px;
	}

	.jodit-workplace #toc {
		display: block;
		max-width: 30px;
	}
`
const StyledCounter = styled.div`
	background-color: #f9f9f9;
	border-radius: 0 0 3px 3px;
	border: 1px solid #dadada;
	border-top: 0;
	display: flex;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
		sans-serif;
	font-size: 12px;
	justify-content: flex-end;
	overflow: hidden;
	padding: 2px 8px;
`

const Editor = (props) => {
	const { imgFolder = 'knowledge-post' } = props
	const { REACT_APP_API_URL, REACT_APP_BASE_URL } = process.env
	const [max] = useState(65535)
	const [value, setValue] = useState('')
	const [size, setSize] = useState(0)
	const [config] = useState({
		language: 'zh_tw',
		readonly: false,
		toolbar: true,
		spellcheck: false,
		askBeforePasteHTML: false,
		askBeforePasteFromWord: false,
		defaultActionOnPaste: 'insert_as_html',
		showCharsCounter: false,
		showWordsCounter: false,
		showXPathInStatusbar: false,
		maxHeight: 500,
		i18n: {
			zh_tw: {
				Alternative: '替代 (Alt)',
			},
		},
		icons: {
			catalog: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" ></svg>`
		},
		buttons:
			'insertTOC,source,|,undo,redo,|,bold,strikethrough,underline,italic,|,ul,ol,|,outdent,indent,align,,font,fontsize,brush,paragraph,|,image,link,|,hr',
		controls: {
			insertTOC: {
				name: 'insertTOC',
				iconURL: '/assets/images/icons/list.svg',
				tooltip: '新增目錄',
				exec: (editor) => {
					const divTOC = editor.createInside.fromHTML(`<img id="toc" src="/assets/images/icons/list-red.svg" />`)
					editor.s.insertNode(divTOC)
				},
			},
		},
		uploader: {
			insertImageAsBase64URI: false,
			url: REACT_APP_API_URL + '/upload/img',
			isSuccess: function (e) {
				return e.code === 200 ? true : false
			},
			getMessage: function (e, resp) {
				return 'Error status:' + e.code
			},
			process: function (e) {
				let respData = {
					baseurl: REACT_APP_BASE_URL,
					files: [e.data],
					isImages: [true],
					messages: [],
				}
				return respData
			},

			contentType: function () {
				return 'application/x-www-form-urlencoded'
			},
			buildData: function (data) {
				return new Promise(function (resolve, reject) {
					var reader = new FileReader()
					reader.readAsDataURL(data.getAll('files[0]')[0])
					reader.onload = function () {
						return resolve({
							image: reader.result,
							target: imgFolder,
						})
					}
					reader.onerror = function (error) {
						reject(error)
					}
				})
			},
		},
		link: {
			formTemplate: (editor) => {
				const i18n = editor.i18n.bind(editor)
				console.log('editor', editor)
				return `
			    <form class="jodit-ui-form" dir="auto">
			      <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
			          <div class="jodit-ui-input jodit-ui-block__url">
			            <span class="jodit-ui-input__label">URL</span>
			            <div class="jodit-ui-input__wrapper">
			                <input class="jodit-ui-input__input" dir="auto" name="url" type="text" data-ref="url_input" ref="url_input" required="true" placeholder="http://">
			            </div>
			          </div>
			      </div>
			      <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle" data-ref="content_input_box" ref="content_input_box">
			          <div class="jodit-ui-input jodit-ui-block__content">
			            <span class="jodit-ui-input__label">${i18n('Text')}</span>
			            <div class="jodit-ui-input__wrapper">
			                <input class="jodit-ui-input__input" dir="auto" name="content" type="text" data-ref="content_input" ref="content_input" placeholder="">
			              </div>
			          </div>
			      </div>
			      <div class="jodit-ui-block jodit-ui-block_align_left jodit-ui-block_size_middle">
			          <div class="jodit-ui-input jodit-ui-block__className">
			            <span class="jodit-ui-input__label">Class name</span>
			            <div class="jodit-ui-input__wrapper">
			                <input class="jodit-ui-input__input" dir="auto" name="className" type="text" data-ref="className_input" ref="className_input" placeholder="">
			              </div>
			          </div>
			      </div>
			      <label class="jodit-ui-checkbox jodit-ui-checkbox_checked_false jodit-ui-checkbox_switch_false jodit-ui-form__target">
			          <span class="jodit-ui-checkbox__label">${i18n('Open in new tab')}</span>
			          <div class="jodit-ui-checkbox__wrapper">
			            <input class="jodit-ui-checkbox__input" dir="auto" name="target" type="checkbox" data-ref="target_checkbox" ref="target_checkbox" placeholder="" value="true" checked>
			          </div>
			      </label>
			      <label class="jodit-ui-checkbox jodit-ui-checkbox_checked_false jodit-ui-checkbox_switch_false jodit-ui-form__nofollow">
			          <span class="jodit-ui-checkbox__label">${i18n('No follow')}</span>
			          <div class="jodit-ui-checkbox__wrapper">
			            <input class="jodit-ui-checkbox__input" dir="auto" name="nofollow" type="checkbox" data-ref="nofollow_checkbox" ref="nofollow_checkbox" placeholder="" value="false" checked>
			          </div>
			      </label>
			      <div class="jodit-ui-block jodit-ui-block_align_full jodit-ui-block_size_middle">
			          <button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_unlink jodit-ui-button_variant_default jodit-ui-button_text-icons_true jodit-ui-block__unlink" type="button" role="button" aria-pressed="false" data-ref="unlink" ref="unlink" style="display: none;">
									<span class="jodit-ui-button__icon"></span>
									<span class="jodit-ui-button__text">${i18n('Unlink')}</span>
								</button>
								<button class="jodit-ui-button jodit-ui-button_size_middle jodit-ui-button_insert jodit-ui-button_variant_primary jodit-ui-button_text-icons_true jodit-ui-block__insert" type="submit" role="button" aria-pressed="false" data-ref="insert" ref="insert">
									<span class="jodit-ui-button__icon"></span>
									<span class="jodit-ui-button__text">${i18n('Insert')}</span>
								</button>
			        </div>
			    </form>
			  `
			},
		},
		events: {
			processPaste: function (event, text, type) {
				let newTest = text
				let linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g
				newTest = newTest.replace(/ dir="ltr"/g, '')
				newTest = newTest.replace(/font-style:normal;/g, '')
				newTest = newTest.replace(/font-weight:400;/g, '')
				newTest = newTest.replace(/color:#000000;/g, '')
				newTest = newTest.replace(/font-size:11pt;/g, '')
				newTest = newTest.replace(/font-weight:normal;/g, '')
				newTest = newTest.replace(
					/(?<=;|"|\s)(-webkit-text-decoration-skip|text-decoration-skip-ink|font-family|white-space|vertical-align|text-decoration|font-variant|background-color|margin|margin-left|margin-right|margin-top|margin-bottom|line-height|display|overflow|border):[^;']*(;)?/g,
					'',
				)
				newTest = newTest.replace(/ style=""/g, '')
				newTest = newTest.replace(/<(span)>(.*?)<\/(span)>/g, '$2')
				newTest = newTest.replace(linkRegex, '<a href="$1" target="_blank" rel="nofollow"')
				return newTest
			},
		},
	})

	const handleTextAreaChange = (newTextAreaValue) => {
		props.onChange(newTextAreaValue)
		setValue(newTextAreaValue)
	}

	useEffect(() => {
		setSize(new Blob([value]).size)
	}, [value])

	return (
		<StyledEditor>
			<JoditEditor config={config} onChange={handleTextAreaChange} value={props.value} />
			<StyledCounter>
				總字數：<span style={{ fontWeight: 'bold', color: size > max && 'red' }}>{size}</span>
			</StyledCounter>
		</StyledEditor>
	)
}

export default Editor

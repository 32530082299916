import React, { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	Divider,
	Textarea,
} from '@chakra-ui/react';
import { createSeo, showSeo, updateSeo } from 'api/Seo';

export const EditSeoPage = () => {
	let history = useHistory();
	const { targetID } = useParams();
	const { register, handleSubmit, errors } = useForm();
	const toast = useToast();
	let [areavalue, setareaValue] = useState();
	let handleInputChange = (e) => {
		let inputValue = e.target.value;
		setareaValue(inputValue);
	};
	const [formData, setFormData] = useState({
		title: null,
		url: null,
		description: null,
		keywords: null,
		meta_schema: '',
	});

	// TODO: cancel all subscriptions and asynchronous tasks
	useEffect(() => {
		if (targetID) {
			// console.log('edit post');
			async function fetchData() {
				const { data } = await showSeo(targetID);
				setFormData(data.data);
				setareaValue(data.data.meta_schema);
			}

			fetchData();
		} else {
			// console.log('new post');
		}
	}, [targetID]);

	const onSubmit = (values) => {
		// console.log('values', JSON.stringify(values));
		if (targetID) {
			updateSeo(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '頁面SEO修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
						history.push('/dashboard/seo');
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			createSeo(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '頁面SEO建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
						history.push('/dashboard/seo');
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};
	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					{targetID ? '修改' : '新增'}網站頁面SEO
				</Heading>
				<Heading as="h3" size="lg" mb={8}>
					頁面SEO
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns="1" spacingX={5} spacingY={5}>
						<FormControl mb={5}>
							<FormLabel htmlFor="title" mb={1}>
								頁面標題
							</FormLabel>
							<Input
								id="title"
								name="title"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.title}
							/>
							{errors.title && <FormHelperText>請填入標題名稱</FormHelperText>}
						</FormControl>
						<FormControl mb={5}>
							<FormLabel htmlFor="url" mb={1}>
								頁面路徑
							</FormLabel>
							<Input id="url" name="url" type="text" ref={register({ required: true })} defaultValue={formData.url} />
							{errors.url && <FormHelperText>請填入頁面路徑</FormHelperText>}
						</FormControl>
						<FormControl mb={5}>
							<FormLabel htmlFor="description" mb={1}>
								頁面描述標籤
							</FormLabel>
							<Input
								id="description"
								name="description"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.description}
							/>
							{errors.description && <FormHelperText>請填入頁面描述標籤</FormHelperText>}
						</FormControl>
						<FormControl mb={5}>
							<FormLabel htmlFor="keywords" mb={1}>
								頁面關鍵字
							</FormLabel>
							<Input
								id="keywords"
								name="keywords"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.keywords}
							/>
							{errors.keywords && <FormHelperText>請填入頁面關鍵字</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<FormControl mb={5}>
						<FormLabel htmlFor="keywords" mb={1}>
							頁面結構化資料標記
						</FormLabel>
						<Textarea
							value={areavalue}
							onChange={handleInputChange}
							placeholder="請填入頁面結構化資料標記"
							ref={register()}
							size="sm"
							name="meta_schema"
							h="10rem"
						/>
						{errors.meta_schema && <FormHelperText>頁面結構化資料標記</FormHelperText>}
					</FormControl>
					<Divider my={10} />
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '發佈'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	);
};

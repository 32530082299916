import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getKnowledgeBannerList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/knowledgeBanners', config)
}

export const showKnowledgeBanner = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/knowledgeBanners/' + id, config)
}

export const createKnowledgeBanner = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.post(REACT_APP_API_URL + '/knowledgeBanners', data, config)
}

export const updateKnowledgeBanner = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.put(REACT_APP_API_URL + '/knowledgeBanners/' + id, data, config)
}

export const createKnowledgePost = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.post(REACT_APP_API_URL + '/knowledge', data, config)
}

export const updateKnowledgePost = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.put(REACT_APP_API_URL + '/knowledge/' + id, data, config)
}

export const showKnowledgePost = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.get(REACT_APP_API_URL + '/knowledge/' + id, config)
}

export const getKnowledgePostList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.get(REACT_APP_API_URL + '/knowledge', config)
}

export const deleteKnowledge = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.delete(REACT_APP_API_URL + '/knowledge/' + id, config)
}

import React, { useState, useEffect } from 'react';
import { Provider as DataProvider } from 'context/dataProvider';
import CalculatorNeedlelessSMPFeatures from './CalculatorNeedlelessSMPFeatures';
import { getCalculatorNeedlelessSMPList } from 'api/CalculatorNeedlelessSMP';
// import calculatorData from 'mocks/calculatorData';
import FilteredTable from 'shared/Table/FilteredTable';

export const CalculatorNeedlelessSMPPage = () => {
	const [originalData, setOriginalData] = useState([]);
	const [data, setData] = useState([]);
	const [type] = useState('Calculator');
	const [filteredRowsData, setFilteredRowsData] = useState([]);
	const [reacttablefilter, setReacttablefilter] = useState(false);
	const [columns, setColumn] = useState([]);
	const [isFinished, setIsFinished] = useState(true);
	const [sortBy, setSortBy] = useState([
		{
			id: 'id',
			desc: true,
		},
	]);

	const fetchSurvey = async () => {
		const { data } = await getCalculatorNeedlelessSMPList();
		// const data = calculatorData;
		setOriginalData(data.data);
		if (data?.data) setData(data.data.filter((item) => item.completeStatus === (isFinished ? 1 : 0)));
	};

	useEffect(() => {
		fetchSurvey();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const context = {
		originalData,
		setOriginalData,
		data,
		setData,
		columns,
		setColumn,
		isFinished,
		setIsFinished,
		sortBy,
		setSortBy,
		type,
		fetchSurvey,
		filteredRowsData,
		setFilteredRowsData,
		reacttablefilter,
		setReacttablefilter,
	};

	return (
		<DataProvider value={context}>
			<CalculatorNeedlelessSMPFeatures />
			<FilteredTable columns={columns} data={data} />
		</DataProvider>
	);
};

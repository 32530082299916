import React from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { SidebarToggleBtn } from 'layout/Sidebar/SidebarToggleBtn';
import { logout } from 'actions/user';
import { connect } from 'react-redux';
import { FaSignOutAlt } from 'react-icons/fa';

const Nav = ({ logout: propsLogout, ...props }) => {
	return (
		<Flex
			pos="sticky"
			top={0}
			borderRadius="30px"
			bg="brand.500"
			align="center"
			as="nav"
			justify="space-between"
			px={3}
			py={2}
			wrap="wrap"
			zIndex="1000"
			{...props}
		>
			<SidebarToggleBtn />
			<Box>
				<IconButton
					bg="white"
					ml={2}
					isRound
					aria-label="登出"
					icon={<FaSignOutAlt />}
					onClick={() => {
						propsLogout();
					}}
				/>
			</Box>
		</Flex>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, { logout })(Nav);

import * as constants from '../constants'
import { setToken, removeToken } from 'utils/auth'

export const login = (data) => (dispatch) => {
  dispatch({
    type: constants.USER_LOGGING_IN,
  })
  setToken(data.token)
  dispatch({
    type: constants.USER_LOGGED_IN,
    payload: data,
  })
}

export function logout() {
  removeToken()
  return {
    type: constants.USER_LOGGED_OUT,
  }
}

import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
	Box,
	HStack,
	Button,
	Input,
	IconButton,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import { BsArrowRepeat } from 'react-icons/bs'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import globalContext from 'context/globalContext';
import dataContext from 'context/dataProvider';
import convertNumber from 'utils/convertNumber';
import { SelectColumnFilter } from 'shared/Table/Filters/SelectColumnFilter';
import { SliderColumnFilter } from 'shared/Table/Filters/SliderColumnFilter';

const CalculatorNeedlelessSMPFeatures = () => {
	const { windowSize } = useContext(globalContext);
	const {
		originalData,
		setData,
		columns,
		setColumn,
		isFinished,
		setIsFinished,
		fetchSurvey,
		filteredRowsData,
		reacttablefilter,
		setReacttablefilter,
	} = useContext(dataContext);
	const [duration, setDuration] = useState({
		startDate: null,
		endDate: null,
	});

	const [alertIsOpen, setAlertIsOpen] = useState(false);
	const [exportData, setExportData] = useState([]);
	const [exportHeader, setExportHeader] = useState([]);
	const alertCancelRef = React.useRef();

	const handleSwitchColumn = (status) => {
		setIsFinished((prevState) => (status === 'finished' ? true : false));
	};

	const handleDatepickerOnChange = (type, date) => {
		if (type === 'start') {
			setDuration({ ...duration, startDate: date });
		} else {
			setDuration({ ...duration, endDate: date });
		}
	};

	const handleRefreshOnClick = () => {
		fetchSurvey();
		setDuration({
			startDate: null,
			endDate: null,
		});
	};

	const handleSearchOnClick = () => {
		handleFilterChange();
	};

	const handleFilterChange = () => {
		setReacttablefilter(!reacttablefilter);
		let filteredData = [];

		const filters = () => {
			Object.assign(filteredData, originalData);

			const isFinishedFilter = () => {
				filteredData = originalData.filter((item) => {
					return item.completeStatus === (isFinished ? 1 : 0);
				});
			};

			const startDateFilter = () => {
				if (duration.startDate) {
					filteredData = filteredData.filter((item) => {
						return new Date(item.updated_at) >= duration.startDate;
					});
				}
			};

			const endDateFilter = () => {
				if (duration.endDate) {
					filteredData = filteredData.filter((item) => {
						const tomorrow = new Date(duration.endDate);
						tomorrow.setDate(duration.endDate.getDate() + 1);
						return new Date(item.updated_at) < tomorrow;
					});
				}
			};

			isFinishedFilter();
			startDateFilter();
			endDateFilter();
		};

		filters();

		setData((prevState) => {
			return filteredData;
		});
	};

	const generateExportData = () => {
		let processedData = [];
		let processedHeader = [];

		// console.log('filteredRowsData', filteredRowsData);
		// console.log('columns', columns);

		filteredRowsData.map((item) => {
			let row = {};
			columns
				.filter((column) => column.accessor !== 'edit')
				.map((column) => {
					let value = item[column.accessor];
					let outputValue = '';

					switch (column.accessor) {
						case 'created_at':
							outputValue = moment(value).format('yyyy-MM-DD');
							break;
						case 'updated_at':
							outputValue = moment(value).format('yyyy-MM-DD');
							break;
						case 'age':
							outputValue = value === 70 ? value + 'up' : value;
							break;
						default:
							outputValue = value || '';
							break;
					}
					return (row = { ...row, [column.accessor]: outputValue });
				});

			processedHeader.push();
			return processedData.push(row);
		});
		// console.log('processedData', processedData);
		setExportData(processedData);
		// console.log(exportData);
	};

	useEffect(() => {
		if (filteredRowsData.length) generateExportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredRowsData]);

	useEffect(() => {
		if (exportData.length) {
			let processedHeader = [];
			const headerName = {
				id: '表單編號',
				updated_at: '填單日期',
				email: 'Email',
				age: '年齡',
				country: '國家',
				areas: '紋髮區域',
				hair_area: '紋髮面積',
				price: '費用',
				notes: '備註',
			};

			Object.keys(exportData[0]).forEach(function (key) {
				processedHeader.push({
					label: headerName[key],
					key,
				});
			});

			setExportHeader(processedHeader);
		}
	}, [exportData]);

	useEffect(() => {
		if (originalData) {
			const defaultColumns = [
				{
					Header: '編號',
					accessor: 'id',
					width: 70,
					sticky: 'left',
				},
				{
					Header: '填單日期',
					accessor: 'updated_at',
					width: 110,
					sticky: 'left',
					Cell: ({ cell: { value } }) => {
						return moment(value).format('yyyy-MM-DD');
					},
				},
				{
					Header: '電子郵件',
					accessor: 'email',
					width: 180,
					sticky: 'left',
				},
				{
					Header: '年齡',
					accessor: 'age',
					width: 100,
					Filter: SliderColumnFilter,
					filter: 'between',
					sticky: 'left',
					Cell: ({ cell: { value } }) => {
						return value + (value === 70 ? 'up' : '');
					},
				},
				{
					Header: '國家',
					accessor: 'country',
					width: 120,
					Filter: SelectColumnFilter,
					filter: 'includes',
				},
				{
					Header: '區域',
					accessor: 'areas',
					width: 120,
					filter: 'fuzzyText',
				},
				{
					Header: '紋髮面積',
					accessor: 'hair_area',
					width: 100,
					Filter: SliderColumnFilter,
					filter: 'between',
					Cell: ({ cell: { value } }) => {
						return convertNumber(value, '');
					},
				},
				{
					Header: '費用',
					accessor: 'price',
					width: 100,
					Filter: SliderColumnFilter,
					filter: 'between',
					Cell: ({ cell: { value } }) => {
						return convertNumber(value);
					},
				},
				// {
				// 	Header: '備註',
				// 	accessor: 'notes',
				// 	wrap: true,
				// },
				// {
				// 	Header: '編輯',
				// 	accessor: 'edit',
				// 	disableSortBy: true,
				// 	sticky: 'right',
				// },
			];

			setColumn(defaultColumns);
			handleFilterChange();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFinished, originalData, setColumn]);

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection={windowSize.width >= 1400 ? 'row' : 'column'}
			mb={2}
			position="relative"
			zIndex="5"
		>
			<HStack position="relative" minWidth="200px" mr={windowSize.width >= 1400 ? '15px' : '0'}>
				<HStack alignItems="center" flex="2" position="relative">
					<Box>
						<DatePicker
							selected={duration.startDate}
							onChange={(date) => handleDatepickerOnChange('start', date)}
							selectsStart
							maxDate={new Date()}
							startDate={duration.startDate}
							endDate={duration.endDate}
							isClearable
							placeholderText="起始時間"
							customInput={<Input bg="white" />}
						/>
					</Box>
					<Box>
						<DatePicker
							selected={duration.endDate}
							onChange={(date) => handleDatepickerOnChange('end', date)}
							selectsEnd
							startDate={duration.startDate}
							endDate={duration.endDate}
							minDate={duration.startDate}
							maxDate={new Date()}
							isClearable
							placeholderText="結束時間"
							customInput={<Input bg="white" />}
						/>
					</Box>
					<Box>
						<Button m={1} onClick={handleSearchOnClick}>
							搜尋
						</Button>
						<IconButton aria-label="Search database" icon={<BsArrowRepeat />} onClick={handleRefreshOnClick} />
						<AlertDialog
							isOpen={alertIsOpen}
							leastDestructiveRef={alertCancelRef}
							onClose={() => setAlertIsOpen(false)}
							isCentered
						>
							<AlertDialogOverlay />
							<AlertDialogContent borderRadius="10px">
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									日期區間有誤
								</AlertDialogHeader>

								<AlertDialogBody>請輸入完整日期區間</AlertDialogBody>

								<AlertDialogFooter>
									<Button
										leftIcon="check"
										colorScheme="brand"
										ref={alertCancelRef}
										onClick={() => setAlertIsOpen(false)}
									>
										我知道了
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</Box>
				</HStack>
			</HStack>
			<HStack flex="1" flexWrap="wrap" mr="0">
				<CSVLink
					data={exportData}
					headers={exportHeader}
					filename="exportedCalculatorNeedlelessSMP.csv"
					onClick={generateExportData}
				>
					<Button m={1}>匯出 Excel</Button>
				</CSVLink>
				<Button
					colorScheme={!isFinished ? 'brand' : 'gray'}
					flex="1"
					m={1}
					onClick={() => handleSwitchColumn('unFinished')}
				>
					未完成表單
				</Button>
				<Button
					colorScheme={isFinished ? 'brand' : 'gray'}
					flex="1"
					m={1}
					onClick={() => handleSwitchColumn('finished')}
				>
					已完成表單
				</Button>
			</HStack>
		</Box>
	);
};

export default CalculatorNeedlelessSMPFeatures;

import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTable, useFilters, useBlockLayout, useSortBy, usePagination, useGlobalFilter } from 'react-table'
import { useSticky } from 'react-table-sticky'
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Badge,
	Box,
	Button,
	Flex,
	Icon,
	IconButton,
	useToast,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { BiCaretDown, BiCaretUp } from 'react-icons/bi'
import { deleteUser } from 'api/User'
import { deleteDoctor } from 'api/Doctor'
import { deleteClinic } from 'api/Clinic'
import { deleteBanner } from 'api/Banner'
import { deleteStaff } from 'api/Staff'
import { deleteNews } from 'api/News'
import { deleteSeo } from 'api/Seo'
import { deleteServiceBanner } from 'api/Service/Banner'
import EditBanner from 'pages/IndexPage/EditBanner'
import EditMember from 'pages/MemberPage/EditMember'
import EditStaff from 'pages/StaffPage/EditStaff'
import EditHairKnowledgeBanner from 'pages/HairKnowledgePage/EditHairKnowledgeBanner'
import EditServiceBanner from 'pages/ServicePage/EditServiceBanner'
import EditSurvey from 'pages/SurveyPage/EditSurvey'
import Modal from 'shared/Modal'
import globalContext from 'context/globalContext'
import context from 'context/dataProvider'
import { GlobalFilter } from './Filters/GlobalFilter'
import { Pagination } from './Pagination'
import { deletePraiseVideo } from 'api/Praise'
import { deleteKnowledge } from 'api/Knowledge'
import { deleteServiceCategory } from 'api/Service/Category'
import EditCalculator from 'pages/CalculatorPage/EditCalculator'
import EditNeedlelessSMP from 'pages/NeedlelessSMPPage/EditNeedlelessSMP'

export const TableContent = () => {
	const { data, setData, columns, type, sortBy, setFilteredRowsData } = useContext(context)
	const { userType, userId } = useContext(globalContext)
	const [currentID, setCurrentID] = useState(0)
	const [sortList, setSortList] = useState([])
	const [alertIsOpen, setAlertIsOpen] = useState()
	const onClose = () => setAlertIsOpen(false)
	const cancelRef = React.useRef()
	const toast = useToast()
	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 0,
			width: 140,
			maxWidth: 400,
		}),
		[],
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		state: { pageIndex, pageSize },
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			disableSortRemove: true,
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: 10,
				sortBy: sortList,
			},
			defaultColumn,
		},
		useFilters, // useFilters!
		useGlobalFilter,
		useSortBy,
		usePagination,
		useBlockLayout,
		useSticky,
	)

	const deleteTableData = (id) => {
		setAlertIsOpen(false)
		switch (type) {
			case 'News':
				deleteNews(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '最新消息刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Seo':
				deleteSeo(id)
					.then(function (res) {
						console.log(res)
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '網站Seo刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Member':
				deleteUser(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '帳號刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Doctor':
				deleteDoctor(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '醫師刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Clinic':
				deleteClinic(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '院區據點刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Index':
				deleteBanner(id)
					.then(function (res) {
						console.log(res)
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '首頁 Banner 刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Staff':
				deleteStaff(id)
					.then(function (res) {
						console.log(res)
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '專員刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Praise':
				deletePraiseVideo(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '分類影片刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'Knowledge-Post':
				deleteKnowledge(id)
				break
			case 'ServiceBanner':
				deleteServiceBanner(id)
					.then(function (res) {
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '服務項目 Banner 刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			case 'ServiceCategory':
				deleteServiceCategory(id)
					.then(function (res) {
						console.log(res)
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '服務項目類別刪除成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break
			default:
				break
		}
		const result = data.filter((item) => item.id !== id)
		setData(() => result)
	}

	useEffect(() => {
		if (sortBy) {
			setSortList(sortBy)
		}
	}, [sortBy])

	useEffect(() => {
		if (rows.length && setFilteredRowsData) {
			let processedRowsData = []
			rows.map((item) => {
				return processedRowsData.push(item.values)
			})
			setFilteredRowsData(processedRowsData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows])

	return (
		<Fragment>
			<AlertDialog isOpen={alertIsOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent borderRadius="10px">
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						刪除
					</AlertDialogHeader>

					<AlertDialogBody>
						你確定要<b>刪除這筆資料</b>嗎？
						<br />
						此操作不能回復哦！
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							取消
						</Button>
						<Button colorScheme="red" onClick={() => deleteTableData(currentID)} ml={3}>
							刪除
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				type={type}
			/>
			<Box overflowX="auto" mb={3}>
				<div {...getTableProps()} className="table sticky">
					<div className="header">
						{headerGroups.map((headerGroup) => (
							<div {...headerGroup.getHeaderGroupProps()} className="tr">
								{headerGroup.headers.map((column) => (
									<div {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
										{column.render('Header')}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<Icon as={BiCaretDown} boxSize="10px" />
												) : (
													<Icon as={BiCaretUp} boxSize="10px" />
												)
											) : null}
										</span>
										<div>{column.filter ? column.render('Filter') : null}</div>
									</div>
								))}
							</div>
						))}
					</div>
					<div {...getTableBodyProps()} className="body">
						{data.length ? (
							page.map((row) => {
								prepareRow(row)
								return (
									<div {...row.getRowProps()} className="tr">
										{row.cells.map((cell) => (
											<div {...cell.getCellProps()} className="td">
												{(() => {
													switch (cell.column.id) {
														case 'edit':
															return (
																<Fragment>
																	{(() => {
																		switch (type) {
																			case 'Doctor':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/doctors/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Clinic':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/clinic/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Index':
																				return <EditBanner targetID={cell.row.original.id} />
																			case 'Staff':
																				return <EditStaff targetID={cell.row.original.id} />
																			case 'News':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/news/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Seo':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/seo/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'ServiceBanner':
																				return <EditServiceBanner targetID={cell.row.original.id} />
																			case 'ServiceCategory':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/service/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Praise':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/praise/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Knowledge-Banner':
																				return <EditHairKnowledgeBanner targetID={cell.row.original.id} />
																			case 'Knowledge-Post':
																				return (
																					<IconButton
																						as={Link}
																						to={'/dashboard/knowledge/post/edit/' + cell.row.original.id}
																						icon={<FaRegEdit />}
																					/>
																				)
																			case 'Survey':
																				return <EditSurvey targetID={cell.row.original.id} />
																			case 'NeedlelessSMP':
																				return <EditNeedlelessSMP targetID={cell.row.original.id} />
																			case 'Calculator':
																				return <EditCalculator data={cell.row.original} />
																			case 'Member':
																				let item = null
																				let ele = <EditMember targetID={cell.row.original.id} />
																				if (userType === 1) {
																					item = ele
																				} else {
																					if (userId === cell.row.original.id) {
																						item = ele
																					} else {
																						item = (
																							<Badge colorScheme="red" mb="5px">
																								不可編輯
																							</Badge>
																						)
																					}
																				}
																				return item
																			default:
																				return (
																					<Modal
																						ctaType="icon"
																						ctaText="edit"
																						title="編輯資料"
																						rowData={cell.row.values}
																					/>
																				)
																		}
																	})()}

																	{(() => {
																		switch (type) {
																			case 'Survey':
																				return null
																			case 'NeedlelessSMP':
																				return null
																			case 'Calculator':
																				return null
																			case 'ServiceBanner':
																				return null
																			case 'ServiceCategory':
																				return null
																			case 'Knowledge-Banner':
																				return null
																			case 'Staff':
																				return (
																					<IconButton
																						ml={2}
																						variant="ghost"
																						colorScheme="red"
																						aria-label="刪除"
																						fontSize="20px"
																						icon={<RiDeleteBin5Fill />}
																						id={cell.row.values.id}
																						onClick={() => {
																							setAlertIsOpen(true)
																							setCurrentID(cell.row.original.id)
																						}}
																					/>
																				)
																			default:
																				return userType === 1 ? (
																					<IconButton
																						ml={2}
																						variant="ghost"
																						colorScheme="red"
																						aria-label="刪除"
																						fontSize="20px"
																						icon={<RiDeleteBin5Fill />}
																						id={cell.row.values.id}
																						onClick={() => {
																							setAlertIsOpen(true)
																							setCurrentID(cell.row.original.id)
																						}}
																					/>
																				) : null
																		}
																	})()}
																</Fragment>
															)
														case 'no':
															return parseInt(row.id) + 1
														default:
															return cell.render('Cell')
													}
												})()}
											</div>
										))}
									</div>
								)
							})
						) : (
							<Box textAlign="center" p={6}>
								NO DATA
							</Box>
						)}
					</div>
				</div>
			</Box>
			<Flex justify="space-between" wrap="wrap">
				<Box>共有 {rows.length} 筆資料</Box>
				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageOptions={pageOptions}
					pageIndex={pageIndex}
					pageSize={pageSize}
					setPageSize={setPageSize}
				/>
			</Flex>
		</Fragment>
	)
}

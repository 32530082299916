import React, { Fragment, useMemo } from 'react';
import { Box } from '@chakra-ui/react';

const Collapse = ({ children, isOpen }) => {
	const open = useMemo(() => isOpen, [isOpen]);

	return (
		<Box height={open ? '100%' : 0} overflow="hidden">
			<Fragment>{children}</Fragment>
		</Box>
	);
};

export default Collapse;

import React, { useEffect, useState } from 'react'
import Table from 'shared/Table/Table'
import EditServiceBanner from 'pages/ServicePage/EditServiceBanner'
import { Provider } from 'context/dataProvider'
import { getBannerList } from 'api/Service/Banner'

export const BannerListPage = (props) => {
  const [data, setData] = useState([])
  const [type] = useState('ServiceBanner')
  const [columns] = useState([
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: '分類',
      accessor: 'type',
      Cell: ({ cell: { value } }) => {
        switch (value) {
          case 30:
            return '男性雄性禿'
          case 31:
            return '女性雄性禿'
          case 32:
            return '髮際線調整術'
          case 33:
            return '植眉/鬍/鬢角'
          case 34:
            return '無針擬真繡髮'
          default:
            return value
        }
      },
    },
    {
      Header: '編輯',
      accessor: 'edit',
      disableSortBy: true,
    },
  ])

  const fetchData = () => {
    getBannerList()
      .then(function (res) {
        setData(res.data.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const context = {
    data,
    setData,
    columns,
    type,
  }

  return (
    <Provider value={context}>
      {data.length < 5 ? <EditServiceBanner />: null}
      <Table />
    </Provider>
  )
}

import React, { useEffect, useState } from 'react'
import Table from 'shared/Table/Table'
import EditMember from 'pages/MemberPage/EditMember'
import { Provider } from 'context/dataProvider'
import { getUserList } from 'api/User'

export const MemberPage = () => {
	const [data, setData] = useState([])
	const [type] = useState('Member')
	const [columns] = useState([
		{
			Header: 'ID',
			accessor: 'id',
		},
		{
			Header: '姓名',
			accessor: 'name',
		},
		{
			Header: 'E-mail',
			accessor: 'email',
		},
		{
			Header: '管理身份',
			accessor: 'type',
			Cell: ({ cell: { value } }) => {
				switch (value) {
					case 0:
						return '諮詢表單管理者'
					case 1:
						return '網站管理者'
					default:
						return value
				}
			},
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	])

	const fetchData = () => {
		getUserList()
			.then(function (res) {
				setData(res.data.data)
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	useEffect(() => {
		fetchData()
	}, [])

	const context = {
		data,
		setData,
		columns,
		type,
	}

	return (
		<Provider value={context}>
			<EditMember />
			<Table />
		</Provider>
	)
}

import React, { useState, useEffect, useContext, Fragment } from 'react'
import { FaSave, FaCheck, FaTimes } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { IoMdClose } from 'react-icons/io'
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	// useToast,
	IconButton,
	Textarea,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import context from 'context/dataProvider'
import convertNumber from 'utils/convertNumber'
// import { updateCalculator, getCalculatorList } from 'api/Calculator';

const EditCalculator = ({ data }) => {
	const { /*setOriginalData,*/ isFinished } = useContext(context)
	const { register, handleSubmit } = useForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	// const toast = useToast();

	const [formData, setFormData] = useState({
		status: null,
		notes: null,
	})

	// const fetchList = async () => {
	// 	// TODO: 待接 Calculator 列表 API
	// 	// const { data } = await getCalculatorList();
	// 	const data = calculatorData;
	// 	setOriginalData(data.data);
	// };

	const onSubmit = (values) => {
		if (data.id) {
			// TODO: 待接更新 Calculator API
			let formData = {
				status: parseInt(values.status),
				notes: values.notes,
			}
			return console.log('onSubmit', data.id, formData)

			// updateCalculator(data.id, values)
			// 	.then(function (res) {
			// 		if (res.data.code === 200) {
			// 			toast({
			// 				title: '通知中心',
			// 				description: '表單修改成功',
			// 				status: 'success',
			// 				duration: 2000,
			// 				isClosable: true,
			// 				position: 'top-right',
			// 			});
			// 			onClose();
			// 			fetchList();
			// 		}
			// 	})
			// 	.catch(function (error) {
			// 		console.log(error);
			// 	});
		}
	}

	const updateSurveyStatus = (status) => {
		if (data.id) {
			return console.log('updateSurveyStatus', status)
			// updateCalculator(targetID, { status })
			// 	.then(function (res) {
			// 		if (res.data.code === 200) {
			// 			toast({
			// 				title: '通知中心',
			// 				description: (status === 0 ? '已退回' : '已略過') + '表單',
			// 				status: 'success',
			// 				duration: 2000,
			// 				isClosable: true,
			// 				position: 'top-right',
			// 			});
			// 			fetchList();
			// 		} else {
			// 			toast({
			// 				title: '通知中心',
			// 				description: '請稍後再試',
			// 				status: 'danger',
			// 				duration: 2000,
			// 				isClosable: true,
			// 				position: 'top-right',
			// 			});
			// 		}
			// 	})
			// 	.catch(function (error) {
			// 		console.log(error);
			// 	});
		}
	}

	useEffect(() => {
		if (isOpen && data) {
			console.log('data', data)
			setFormData(data)
		}
	}, [isOpen, data])

	return (
		<Flex display="flex">
			<Fragment>
				<IconButton onClick={onOpen} icon={<FaRegEdit />} mr={2} />
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent borderRadius="10px">
						<ModalHeader>{data.id ? '修改' : '新增'}表單</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<form onSubmit={handleSubmit(onSubmit)}>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											表單編號
										</FormLabel>
										<Box flex="2">
											{data.id}
											<Input type="hidden" name="status" defaultValue={formData.status} ref={register} />
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											填單日期
										</FormLabel>
										<Box flex="2">{moment(data.created_at).format('yyyy-MM-DD')}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											電子郵件
										</FormLabel>
										<Box flex="2">{data.member.email}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											年齡
										</FormLabel>
										<Box flex="2">{data.member.age}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											國家
										</FormLabel>
										<Box flex="2">{data.member.country}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											區域
										</FormLabel>
										<Box flex="2">{data.area}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											濃密程度
										</FormLabel>
										<Box flex="2">{data.fullness}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											株數
										</FormLabel>
										<Box flex="2">{convertNumber(data.grafts, '')}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											根數
										</FormLabel>
										<Box flex="2">{convertNumber(data.hairs, '')}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											費用
										</FormLabel>
										<Box flex="2">{convertNumber(data.price)}</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="notes">
											備註
										</FormLabel>
										<Box flex="2">
											<Textarea placeholder="" id="notes" name="notes" defaultValue={formData.notes} ref={register} />
										</Box>
									</Flex>
								</FormControl>
								<Flex mt={4} pb={2} justify="flex-end">
									<Button variant="ghost" onClick={onClose} mr={3}>
										<Box as={IoMdClose} mr={2} />
										取消
									</Button>
									<Button
										colorScheme="gray"
										type="submit"
										mr={3}
										onClick={(e) => setFormData({ ...formData, status: isFinished ? 1 : 0 })}
									>
										<Box as={FaSave} mr={2} />
										儲存
									</Button>
									{isFinished ? (
										<Button colorScheme="red" type="submit" onClick={(e) => setFormData({ ...formData, status: 0 })}>
											<Box as={FaTimes} mr={2} />
											退回
										</Button>
									) : (
										<Button colorScheme="brand" type="submit" onClick={(e) => setFormData({ ...formData, status: 1 })}>
											<Box as={FaCheck} mr={2} />
											提交完成
										</Button>
									)}
								</Flex>
							</form>
						</ModalBody>
					</ModalContent>
				</Modal>
				{isFinished ? (
					<Button ml="auto" colorScheme="red" variant="solid" onClick={() => updateSurveyStatus(0)}>
						退回
					</Button>
				) : (
					<Button ml="auto" colorScheme="brand" variant="solid" onClick={() => updateSurveyStatus(1)}>
						略過
					</Button>
				)}
			</Fragment>
		</Flex>
	)
}

export default EditCalculator

import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import context from 'context/globalContext';
import { MemberPage } from 'pages/MemberPage/MemberPage';
import { SurveyPage } from 'pages/SurveyPage/SurveyPage';
import { DoctorsPage } from 'pages/DoctorsPage/DoctorsPage';
import { EditDoctorPage } from 'pages/DoctorsPage/EditDoctorPage';
import { EditDoctorBannerPage } from 'pages/DoctorsPage/EditDoctorBannerPage';
import { ClinicPage } from 'pages/ClinicPage/ClinicPage';
import { EditClinicPage } from 'pages/ClinicPage/EditClinicPage';
import { IndexPage } from 'pages/IndexPage/IndexPage';
import { StaffPage } from 'pages/StaffPage/StaffPage';
import { HairKnowledgePosts } from 'pages/HairKnowledgePage/HairKnowledgePosts';
import { HairKnowledgeBanner } from 'pages/HairKnowledgePage/HairKnowledgeBanner';
import { EditHairKnowledgePosts } from 'pages/HairKnowledgePage/EditHairKnowledgePosts';
import { PraisePage } from 'pages/PraisePage/PraisePage';
import { EditPraisePage } from 'pages/PraisePage/EditPraisePage';
import { EditPraiseVideoPage } from 'pages/PraisePage/EditPraiseVideoPage';
import { BannerListPage } from 'pages/ServicePage/BannerListPage';
import { CategoryListPage } from 'pages/ServicePage/CategoryListPage';
import { EditServiceCategoryPage } from 'pages/ServicePage/EditServiceCategoryPage';
// import { NewsPage } from 'pages/NewsPage/NewsPage';
// import { EditNewsPage } from 'pages/NewsPage/EditNewsPage';
import { SeoPage } from 'pages/SeoPage/SeoPage';
import { EditSeoPage } from 'pages/SeoPage/EditSeoPage';
import { CalculatorPage } from 'pages/CalculatorPage/CalculatorPage';
import { CalculatorNeedlelessSMPPage } from 'pages/CalculatorNeedlelessSMPPage/CalculatorNeedlelessSMPPage';
import { NeedlelessSMPPage } from 'pages/NeedlelessSMPPage/NeedlelessSMPPage';

const AdminRoute = ({ children, ...rest }) => {
	// INFO: userType: 0 (guest); 1 (admin)
	const { userType } = useContext(context);

	return <Route {...rest} render={({ location }) => (userType === 1 ? children : <Redirect to="/dashboard" />)} />;
};

const Router = () => {
	return (
		<Switch>
			<Route path="/dashboard/survey" component={SurveyPage} />
			<Route path="/dashboard/needlelessSMP" component={NeedlelessSMPPage} />
			<Route path="/dashboard/member" component={MemberPage} />
			<Route path="/dashboard/staff" component={StaffPage} />
			<Route path="/dashboard/calculator" component={CalculatorPage} />
			<Route path="/dashboard/calculator-needlelessSMP/" component={CalculatorNeedlelessSMPPage} />
			<AdminRoute>
				{/* <Route path="/dashboard/news/list" exact component={NewsPage} /> */}
				{/* <Route path="/dashboard/news/add" exact component={EditNewsPage} /> */}
				{/* <Route path="/dashboard/news/edit/:targetID" exact component={EditNewsPage} /> */}
				<Route path="/dashboard/doctors/list" exact component={DoctorsPage} />
				<Route path="/dashboard/doctors/add" exact component={EditDoctorPage} />
				<Route path="/dashboard/doctors/edit/:targetID" exact component={EditDoctorPage} />
				<Route path="/dashboard/doctors/banner" exact component={EditDoctorBannerPage} />
				<Route path="/dashboard/clinic/list" exact component={ClinicPage} />
				<Route path="/dashboard/clinic/add" exact component={EditClinicPage} />
				<Route path="/dashboard/clinic/edit/:targetID" exact component={EditClinicPage} />
				<Route path="/dashboard/index" component={IndexPage} />
				<Route path="/dashboard/knowledge/banner" component={HairKnowledgeBanner} />
				<Route path="/dashboard/knowledge/post" exact component={HairKnowledgePosts} />
				<Route path="/dashboard/knowledge/post/add" exact component={EditHairKnowledgePosts} />
				<Route path="/dashboard/knowledge/post/edit/:targetID" exact component={EditHairKnowledgePosts} />
				<Route path="/dashboard/praise/list" exact component={PraisePage} />
				<Route path="/dashboard/praise/banner/edit/:targetID" exact component={EditPraisePage} />
				<Route path="/dashboard/praise/add" exact component={EditPraiseVideoPage} />
				<Route path="/dashboard/praise/edit/:targetID" exact component={EditPraiseVideoPage} />
				<Route path="/dashboard/service/banners" exact component={BannerListPage} />
				<Route path="/dashboard/service/categories" exact component={CategoryListPage} />
				<Route path="/dashboard/service/add" exact component={EditServiceCategoryPage} />
				<Route path="/dashboard/service/edit/:targetID" exact component={EditServiceCategoryPage} />
				<Route path="/dashboard/seo" exact component={SeoPage} />
				<Route path="/dashboard/seo/add" exact component={EditSeoPage} />
				<Route path="/dashboard/seo/edit/:targetID" exact component={EditSeoPage} />
			</AdminRoute>
		</Switch>
	);
};

export default Router;

import React from 'react'
import styled from 'styled-components'
import { TableContent } from 'shared/Table/TableContent'

const StyledTable = styled.div`
	display: block;
	max-width: 100%;
	padding: 1rem 0;

	.table {
		background: white;
		border-radius: 0 0 10px 10px;
		max-height: calc(100vh - 302px);

		.tr {
			min-width: 100%;
			:last-child {
				.td {
					border-bottom: 0;
				}
			}
		}

		.th,
		.td {
			background: white;
			border-bottom: 2px solid #eeeeee;
			border-right: 2px solid #eeeeee;
			margin: 0;
			overflow: hidden;
			padding: 0.5rem;

			:last-child {
				border-right: 0;
			}

			:not([data-sticky-td]) {
				flex-grow: 1;
			}

			span {
				display: inline-flex;
				margin-left: 0.5em;
			}

			.resizer {
				display: inline-block;
				width: 5px;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				transform: translateX(50%);
				z-index: 1;

				&.isResizing {
					background: red;
				}
			}
		}

		&.sticky {
			overflow: auto;
			.header,
			.footer {
				position: sticky;
				z-index: 1;
				width: fit-content;
				min-width: 100%;
			}

			.header {
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
				top: 0;
			}

			.footer {
				bottom: 0;
			}

			.body {
				max-height: 68vh;
				position: relative;
				z-index: 0;
			}

			[data-sticky-td] {
				position: sticky;
			}

			[data-sticky-last-left-td] {
				box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.05);
			}

			[data-sticky-first-right-td] {
				box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.05);
			}
		}
	}
`

const Table = () => {
	return (
		<StyledTable>
			<TableContent />
		</StyledTable>
	)
}

export default Table

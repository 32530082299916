import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getClinicList } from 'api/Clinic';

export const ClinicPage = () => {
	const [data, setData] = useState([]);
	const [type] = useState('Clinic');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'no',
		},
		{
			Header: '據點名稱',
			accessor: 'name',
		},
		{
			Header: '電話',
			accessor: 'phone',
		},
		{
			Header: '地址',
			accessor: 'address',
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getClinicList()
			.then(function (res) {
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
				<Button as={Link} to="add" ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					新增院區據點
				</Button>
			</Flex>
			<Table />
		</Provider>
	);
};

import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getBlock = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/serviceItemsContents/' + id, config)
}

export const createBlock = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.post(REACT_APP_API_URL + '/serviceItemsContents', data, config)
}

export const updateBlock = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }
  console.log(id, data)
  return Axios.put(REACT_APP_API_URL + '/serviceItemsContents/' + id, data, config)
}

export const deleteBlock = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.delete(REACT_APP_API_URL + '/serviceItemsContents/' + id, config)
}

import React, { useEffect, useState, useContext, useRef } from 'react'
import { FaSave } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Divider,
	Select,
	Badge,
} from '@chakra-ui/react'
import globalContext from 'context/globalContext'
import { uploadImage } from 'api/Upload'
import { createPraiseVideo, showPraiseVideo, updatePraiseVideo } from 'api/Praise'

export const EditPraiseVideoPage = (props) => {
	let history = useHistory()
	const { targetID } = useParams()
	const { REACT_APP_BASE_URL } = process.env
	const { windowSize } = useContext(globalContext)
	const { register, handleSubmit, errors } = useForm()
	const toast = useToast()
	const praise_video_img_upload = useRef()
	const [formData, setFormData] = useState({
		title: null,
		url: null,
		category: '',
		praise_video_img: null,
	})
	const [selectedCategory, setSelectedCategory] = useState('')

	const videoCategory = [
		{ id: 1, name: '雄性禿' },
		{ id: 2, name: '髮際線調整術' },
		{ id: 3, name: '植眉|植鬍|鬢角' },
	]

	useEffect(() => {
		if (targetID) {
			async function fetchData() {
				const { data } = await showPraiseVideo(targetID)
				setFormData(data.data)
				setSelectedCategory(data.data.category)
			}

			fetchData()
		} else {
			console.log('new')
		}
	}, [targetID])

	const onSubmit = (values) => {
		if (targetID) {
			updatePraiseVideo(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '分類影片修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/praise')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			createPraiseVideo(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '分類影片建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/praise')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const onChangePicture = (e, target) => {
		if (e !== null && e.target.files[0]) {
			const reader = new FileReader()
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'praiseVideo')
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})

					setFormData({
						...formData,
						[target]: data.data,
					})
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				}
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const column = windowSize.width > 992 ? '2' : '1'

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					{targetID ? '修改' : '新增'}選單分類影片
				</Heading>
				<Heading as="h3" size="lg">
					分類影片
				</Heading>
				<Divider my={10} />
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns={column} spacingX={5} spacingY={5}>
						<FormControl mb={5}>
							<FormLabel htmlFor="title" mb={1}>
								標題
							</FormLabel>
							<Input
								id="title"
								name="title"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.title}
							/>
							{errors.title && <FormHelperText>請填入標題名稱</FormHelperText>}
						</FormControl>
						<FormControl mb={5}>
							<FormLabel htmlFor="url" mb={1}>
								URL
							</FormLabel>
							<Input id="url" name="url" type="url" ref={register({ required: true })} defaultValue={formData.url} />
							{errors.url && <FormHelperText>請填入影片連結網址</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="category">影片分類</FormLabel>
							<Box flex="2">
								<Select
									placeholder="請選擇影片分類"
									name="category"
									value={selectedCategory}
									ref={register({ required: true })}
									onChange={(event) => setSelectedCategory(event.target.value)}
								>
									{videoCategory.map((person, i) => {
										const { id, name } = person
										return (
											<option value={id} key={i}>
												{name}
											</option>
										)
									})}
								</Select>
							</Box>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="praise_video_img" mb={1}>
								圖片 <Badge ml={'20px'}>1920*1080(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="praise_video_img"
								ref={praise_video_img_upload}
								onChange={(e) => onChangePicture(e, 'praise_video_img')}
							/>
							<Input
								type="hidden"
								name="praise_video_img"
								defaultValue={formData.praise_video_img}
								ref={register({ required: true })}
							/>
							{formData.praise_video_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.praise_video_img}
											alt="圖片"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													praise_video_img: null,
												})

												praise_video_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.banner_web_img && <FormHelperText>請指定一張圖片為影片用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>

					<Divider my={10} />
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '發佈'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	)
}

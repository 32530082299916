import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getCategoryList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/serviceItems', config)
}

export const showCategory = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/serviceItems/' + id, config)
}

export const createCategory = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.post(REACT_APP_API_URL + '/serviceItems', data, config)
}

export const updateCategory = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }
  console.log(data)

  return Axios.put(REACT_APP_API_URL + '/serviceItems/' + id, data, config)
}

export const deleteServiceCategory = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.delete(REACT_APP_API_URL + '/serviceItems/' + id, config)
}

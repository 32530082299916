import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getPraiseBanner = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.get(REACT_APP_API_URL + '/praises', config)
}

export const createPraise = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.post(REACT_APP_API_URL + '/praises', data, config)
}
export const showPraise = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/praises/' + id, config)
}

export const updatePraise = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.put(REACT_APP_API_URL + '/praises/' + id, data, config)
}

export const deletePraise = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.delete(REACT_APP_API_URL + '/praises/' + id, config)
}

// praise video 好評見證影片列表
export const getPraiseList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.get(REACT_APP_API_URL + '/praiseVideos', config)
}

export const createPraiseVideo = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.post(REACT_APP_API_URL + '/praiseVideos', data, config)
}
export const showPraiseVideo = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.get(REACT_APP_API_URL + '/praiseVideos/' + id, config)
}

export const updatePraiseVideo = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.put(REACT_APP_API_URL + '/praiseVideos/' + id, data, config)
}

export const deletePraiseVideo = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  }

  return Axios.delete(REACT_APP_API_URL + '/praiseVideos/' + id, config)
}

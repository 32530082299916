import React, { useEffect, useState, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Badge,
} from '@chakra-ui/react';
import globalContext from 'context/globalContext';
import { uploadImage } from 'api/Upload';
import { createDoctorBanner, getDoctorBanner, getDoctorBannerList, updateDoctorBanner } from 'api/Doctor';

export const EditDoctorBannerPage = (props) => {
	let [targetID, setTargetID] = useState(null);
	const { REACT_APP_BASE_URL } = process.env;
	const { windowSize } = useContext(globalContext);
	const { register, handleSubmit, errors } = useForm();
	const toast = useToast();
	const [list, setList] = useState([]);
	const [formData, setFormData] = useState({
		web_img: null,
		phone_img: null,
		url: null,
	});

	const fetchData = async () => {
		const { data } = await getDoctorBannerList();
		setList(data.data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (list.length) {
			setTargetID(1);
			getDoctorBanner(1)
				.then((res) => {
					const { data, code } = res.data;
					if (code === 200) {
						setFormData(data);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [list]);

	const onSubmit = (values) => {
		if (targetID) {
			updateDoctorBanner(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: 'Banner 修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			createDoctorBanner(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};

	const onChangePicture = (e, target) => {
		if (e.target.files[0]) {
			const reader = new FileReader();
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'doctor');
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});

					setFormData({
						...formData,
						[target]: data.data,
					});
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});
				}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const column = windowSize.width > 640 ? '2' : '1';

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			{
				<Box w="100%" maxWidth="1000px">
					<Heading as="h2" size="xl" mt={5} mb={8}>
						醫師資訊頁面 Banner
					</Heading>
					<form onSubmit={handleSubmit(onSubmit)}>
						<SimpleGrid columns={column} spacingX={5} spacingY={5}>
							<FormControl>
								<FormLabel htmlFor="web_img_upload" mb={1}>
									電腦版 Banner <Badge ml={'20px'}>1920x1080(1 MB)</Badge>
								</FormLabel>
								<Input
									type="file"
									id="web_img_upload"
									name="web_img_upload"
									onChange={(e) => onChangePicture(e, 'web_img')}
								/>
								<Input
									type="hidden"
									name="web_img"
									defaultValue={formData.web_img}
									ref={register({ required: true })}
								/>
								{formData.web_img ? (
									<HStack alignItems="flex-end">
										<Box w="100%">
											<Image
												size="200px"
												objectFit="cover"
												src={REACT_APP_BASE_URL + formData.web_img}
												alt="電腦版 Banner"
											/>
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														web_img: null,
													});
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.web_img && <FormHelperText>請指定一張圖片為電腦版 Banner</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="phone_img_upload" mb={1}>
									手機版 Banner <Badge ml={'20px'}>375x320(1 MB)</Badge>
								</FormLabel>
								<Input
									type="file"
									id="phone_img_upload"
									name="phone_img_upload"
									onChange={(e) => onChangePicture(e, 'phone_img')}
								/>
								<Input
									type="hidden"
									name="phone_img"
									defaultValue={formData.phone_img}
									ref={register({ required: true })}
								/>
								{formData.phone_img ? (
									<HStack alignItems="flex-end">
										<Box w="100%">
											<Image
												size="200px"
												objectFit="cover"
												src={REACT_APP_BASE_URL + formData.phone_img}
												alt="手機版 Banner"
											/>
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														phone_img: null,
													});
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.phone_img && <FormHelperText>請指定一張圖片為手機版 Banner</FormHelperText>}
							</FormControl>
						</SimpleGrid>
						<FormControl mt={4}>
							<FormLabel htmlFor="url" mb={1}>
								URL
							</FormLabel>
							<Input type="text" id="url" name="url" defaultValue={formData.url} ref={register} />
						</FormControl>
						<Flex mt={4} pb={2} justify="flex-end">
							<Button colorScheme="brand" type="submit">
								<Box as={FaSave} mr={2} />
								{targetID ? '修改' : '新增'}
							</Button>
						</Flex>
					</form>
				</Box>
			}
		</Flex>
	);
};

import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getBannerList = () => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/serviceItemsBanners', config)
}

export const showBanner = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.get(REACT_APP_API_URL + '/serviceItemsBanners/' + id, config)
}

export const createBanner = (data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.post(REACT_APP_API_URL + '/serviceItemsBanners', data, config)
}

export const updateBanner = (id, data) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }
  console.log(data)

  return Axios.put(REACT_APP_API_URL + '/serviceItemsBanners/' + id, data, config)
}

export const deleteServiceBanner = (id) => {
  const { REACT_APP_API_URL } = process.env
  let config = {
    headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
  }

  return Axios.delete(REACT_APP_API_URL + '/serviceItemsBanners/' + id, config)
}

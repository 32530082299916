import React, { useEffect, useState } from 'react';
import Table from 'shared/Table/Table';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { Provider } from 'context/dataProvider';
import { getCategoryList } from 'api/Service/Category';

export const CategoryListPage = (props) => {
	const [data, setData] = useState([]);
	const [type] = useState('ServiceCategory');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'no',
		},
		{
			Header: '分類',
			accessor: 'category',
			Cell: ({ cell: { value } }) => {
				console.log(value);
				switch (parseInt(value)) {
					case 0:
						return '男性雄性禿';
					case 1:
						return '女性雄性禿';
					case 2:
						return '髮際線調整術';
					case 3:
						return '植眉/鬍/鬢角';
					case 4:
						return '無針擬真繡髮';
					default:
						return value;
				}
			},
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getCategoryList()
			.then(function (res) {
				setData(res.data.data);
				console.log(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
				<Button as={Link} to="add" ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					新增服務項目類別
				</Button>
			</Flex>
			{/* {data.length < 5 ? (
			) : null} */}
			<Table />
		</Provider>
	);
};

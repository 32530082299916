import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { RiUserAddLine } from 'react-icons/ri';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getDoctorList } from 'api/Doctor';

export const DoctorsPage = () => {
	const [data, setData] = useState([]);
	const [type] = useState('Doctor');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'no',
		},
		{
			Header: '姓名',
			accessor: 'name',
		},
		{
			Header: '職稱',
			accessor: 'title',
		},
		{
			Header: '現任',
			accessor: 'job',
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getDoctorList()
			.then(function (res) {
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
				<Button as={Link} to="add" ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiUserAddLine} mr={2} />
					新增醫師
				</Button>
			</Flex>
			<Table />
		</Provider>
	);
};

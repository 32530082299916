import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ChakraProvider, CSSReset, useToast } from '@chakra-ui/react';
import theme from 'common/customTheme';
import { Provider as GlobalProvider } from 'context/globalContext';
import { connect } from 'react-redux';
import { login } from 'actions/user';
import Axios from 'axios';
import {
	userIsAuthenticatedRedir,
	userIsNotAuthenticatedRedir,
	userIsAdminRedir,
	// userIsAuthenticated,
	// userIsNotAuthenticated,
} from 'auth';
import { getToken } from 'utils/auth';

import { Dashboard } from 'layout/Dashboard/Dashboard';
import LoginPage from 'pages/LoginPage/LoginPage';

const Login = userIsNotAuthenticatedRedir(LoginPage);
const Admin = userIsAuthenticatedRedir(userIsAdminRedir(Dashboard));

const Container = (props) => {
	const { REACT_APP_API_URL } = process.env;
	const toast = useToast();
	const [windowSize, setWindowsSize] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	const sidebarInit = windowSize.width > 640 ? true : false;
	const [sidebarOpen, toggleSidebarOpen] = useState(sidebarInit);
	const [userType, setUserType] = useState(0);
	const [userId, setUserId] = useState();

	const globalContextValue = {
		windowSize,
		setWindowsSize,
		sidebarOpen,
		toggleSidebarOpen,
		userType,
		setUserType,
		userId,
		setUserId,
	};

	useEffect(() => {
		console.log(`${process.env.REACT_APP_NAME} version: v${process.env.REACT_APP_VERSION}`);
	}, []);

	useEffect(() => {
		let config = {
			headers: { Authorization: `Bearer ${getToken()}` },
		};
		Axios.post(REACT_APP_API_URL + '/check', {}, config)
			.then(function (response) {
				if (response.data.code === 200) {
					props.login({
						token: getToken(),
						isAdmin: true,
					});
					setUserType((prevState) => response.data.type);
					setUserId((prevState) => response.data.uid);
				}
				if (response.data.code === 210) {
					// INFO:: TOKEN 過期
				}
			})
			.catch(function (error) {
				toast({
					title: '通知中心',
					description: '請聯繫工程單位',
					status: 'error',
					duration: 2000,
					isClosable: true,
					position: 'top-right',
				});
			});

		const handleResize = () => {
			setWindowsSize({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		};

		window.addEventListener('resize', handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [REACT_APP_API_URL]);

	return (
		<GlobalProvider value={globalContextValue}>
			<ChakraProvider theme={theme}>
				<CSSReset />
				<Router>
					<Switch>
						<Route path="/login" component={Login} />
						<Route path="/dashboard" component={Admin} user={props.user} />
						<Route exact path="/" component={Login} />
						<Redirect to="/login" />
					</Switch>
				</Router>
			</ChakraProvider>
		</GlobalProvider>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, { login })(Container);

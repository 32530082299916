import React, { useState, useEffect, useContext } from 'react'
import { RiAddLine } from 'react-icons/ri'
import { FaSave } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { IoMdClose } from 'react-icons/io'
import {
	HStack,
	Button,
	Image,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormHelperText,
	useToast,
	IconButton,
	Select,
	Badge,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { createBanner, updateBanner, showBanner, getBannerList } from 'api/Service/Banner'
import { uploadImage } from 'api/Upload'
import context from 'context/dataProvider'

const EditServiceBanner = (props) => {
	const { setData } = useContext(context)
	const targetID = props.targetID || null
	const { REACT_APP_BASE_URL } = process.env
	const { register, handleSubmit, errors } = useForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()

	const [formData, setFormData] = useState({
		web_img: null,
		phone_img: null,
		type: null,
	})

	const serviceCategory = [
		{ id: 30, title: '男性雄性禿' },
		{ id: 31, title: '女性雄性禿' },
		{ id: 32, title: '髮際線調整術' },
		{ id: 33, title: '植眉/鬍/鬢角' },
		{ id: 34, title: '無針擬真繡髮' },
	]

	const fetchList = async () => {
		const { data } = await getBannerList()
		setData(data.data)
	}

	const onSubmit = (values) => {
		console.log(values)
		if (targetID) {
			updateBanner(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: 'Banner 修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			createBanner(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: 'Banner 建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const onChangePicture = (e, target) => {
		if (e.target.files[0]) {
			const reader = new FileReader()
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'index')
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})

					setFormData({
						...formData,
						[target]: data.data,
					})
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				}
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	useEffect(() => {
		if (isOpen && targetID) {
			console.log(targetID)
			async function fetchData() {
				const { data } = await showBanner(targetID)
				setFormData(data.data)
			}

			fetchData()
		} else {
			setFormData({ web_img: null, phone_img: null, type: null })
		}
		console.log(formData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, targetID])

	return (
		<Flex display={targetID ? 'inline' : 'flex'}>
			{targetID ? (
				<IconButton onClick={onOpen} icon={<FaRegEdit />} />
			) : (
				<Button onClick={onOpen} ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					{targetID ? '修改' : '新增'} Banner
				</Button>
			)}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent borderRadius="10px">
					<ModalHeader>{targetID ? '修改' : '新增'} Banner</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl>
								<FormLabel htmlFor="type" mb={1}>
									分類
								</FormLabel>
								<Select
									placeholder="選擇分類"
									name="type"
									value={formData.type || ''}
									ref={register({ required: true })}
									isReadOnly
								>
									{serviceCategory.map((type, i) => {
										const { id, title } = type
										return (
											<option value={id} key={i}>
												{title}
											</option>
										)
									})}
								</Select>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel htmlFor="web_img_upload" mb={1}>
									電腦版 Banner <Badge ml={'20px'}>1920*320(1 MB)</Badge>
								</FormLabel>
								<Input
									type="file"
									id="web_img_upload"
									name="web_img_upload"
									onChange={(e) => onChangePicture(e, 'web_img')}
								/>
								<Input
									type="hidden"
									name="web_img"
									defaultValue={formData.web_img}
									ref={register({ required: true })}
								/>
								{formData.web_img ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image
												size="200px"
												objectFit="cover"
												src={REACT_APP_BASE_URL + formData.web_img}
												alt="電腦版 Banner"
											/>
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														web_img: null,
													})
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.web_img && <FormHelperText>請指定一張圖片為電腦版 Banner</FormHelperText>}
							</FormControl>
							<FormControl mt={4}>
								<FormLabel htmlFor="phone_img_upload" mb={1}>
									手機版 Banner <Badge ml={'20px'}>375*320(1 MB)</Badge>
								</FormLabel>
								<Input
									type="file"
									id="phone_img_upload"
									name="phone_img_upload"
									onChange={(e) => onChangePicture(e, 'phone_img')}
								/>
								<Input
									type="hidden"
									name="phone_img"
									defaultValue={formData.phone_img}
									ref={register({ required: true })}
								/>
								{formData.phone_img ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image
												size="200px"
												objectFit="cover"
												src={REACT_APP_BASE_URL + formData.phone_img}
												alt="手機版 Banner"
											/>
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														phone_img: null,
													})
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.phone_img && <FormHelperText>請指定一張圖片為手機版 Banner</FormHelperText>}
							</FormControl>
							<Flex mt={4} pb={2} justify="flex-end">
								<Button variant="ghost" onClick={onClose} mr={3}>
									<Box as={IoMdClose} mr={2} />
									取消
								</Button>
								<Button colorScheme="brand" type="submit">
									<Box as={FaSave} mr={2} />
									{targetID ? '修改' : '新增'}
								</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export default EditServiceBanner

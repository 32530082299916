import React, { useContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { FaSave } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { updateUser } from 'api/User'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	IconButton,
	Box,
	useToast,
} from '@chakra-ui/react'
import context from 'context/dataProvider'
import { CustomForm } from 'shared/Form/Form'

const CustomModal = (props) => {
	const methods = useForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { ctaType, ctaText, title, rowData } = props
	const { data, setData, type } = useContext(context)
	const toast = useToast()

	const onSubmit = (formData) => {
		const newData = Array.from(data)
		const originDataIndex = data.findIndex((item) => item.id === parseInt(formData.id, 10))
		const inputArr = Object.keys(data[0])

		switch (type) {
			case 'Member':
				updateUser(formData.id, { name: formData.name, password: formData.password })
					.then(function (res) {
						console.log(res)
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '帳號修改成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							})
						}
					})
					.catch(function (error) {
						console.log(error)
					})
				break

			default:
				break
		}
		inputArr.forEach((val) => {
			if (val !== 'subRows' && val !== 'id') {
				newData[originDataIndex][val] = formData[val]
			}
		})
		setData((prevState) => (prevState = newData))
		onClose()
	}

	const handleOnClick = () => {
		onOpen()
	}

	const OtherButtons = () => {
		switch (type) {
			case 'Survey':
				return (
					<Button colorScheme="brand" leftIcon="check">
						提交完成
					</Button>
				)
			default:
				return null
		}
	}

	const CustomButton = () => {
		return ctaType === 'icon' ? (
			<IconButton onClick={handleOnClick} icon={ctaText} />
		) : (
			<Button onClick={handleOnClick}>{ctaText}</Button>
		)
	}

	return (
		<FormProvider {...methods}>
			<CustomButton />
			<Modal isOpen={isOpen} onClose={onClose} size="800px" isCentered>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<ModalOverlay />
					<ModalContent borderRadius="10px">
						<ModalHeader>{title}</ModalHeader>
						<ModalCloseButton />
						<ModalBody maxHeight="60vh" overflowY="auto">
							<CustomForm data={rowData} />
						</ModalBody>
						<ModalFooter>
							<Button variant="ghost" onClick={onClose} mr={3}>
								<Box as={IoMdClose} mr={2} />
								取消
							</Button>
							<Button colorScheme="gray" mr={3} type="submit">
								<Box as={FaSave} mr={2} />
								儲存
							</Button>
							<OtherButtons />
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
		</FormProvider>
	)
}

export default CustomModal

import React, { useContext } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { Box, Input, Stack, Heading, Button, FormHelperText, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import Axios from 'axios'
import { connect } from 'react-redux'
import context from 'context/globalContext'
import { login } from 'actions/user'

const AnimateLoginBg = styled(Box)`
  &:before {
    background: linear-gradient(300deg, #68bf79 50%, #d7ecdb 50.05%);
    content: '';
    display: block;
    height: 150%;
    position: fixed;
    width: 150%;
    z-index: -1;
  }
`

const LoginPage = (props) => {
  const { setUserType, setUserId } = useContext(context)
  const { register, handleSubmit, errors } = useForm()
  const { REACT_APP_API_URL } = process.env
  const toast = useToast()

  const onSubmit = (values) => {
    // INFO:: values = form output
    Axios.post(REACT_APP_API_URL + '/login', {
      email: values.email,
      password: values.password,
    })
      .then(function (response) {
        if (response.data.code === 200) {
          const { data } = response.data
          toast({
            title: '通知中心',
            description: '會員登入成功',
            status: 'success',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          })
          props.login({
            token: data.api_token,
            isAdmin: true,
          })

          setUserType((prevState) => data.type)
          setUserId((prevState) => data.uid)
        }
        if (response.data.code === 202) {
          toast({
            title: '通知中心',
            description: '帳號或密碼錯誤',
            status: 'error',
            duration: 2000,
            isClosable: true,
            position: 'top-right',
          })
        }
      })
      .catch(function (error) {
        toast({
          title: '通知中心',
          description: '請聯繫工程單位',
          status: 'error',
          duration: 2000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  return (
    <AnimateLoginBg alignItems="center" d="flex" h="100vh" justifyContent="center" w="100vw">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box bg="white" maxWidth="300px" p={10} borderRadius={30} shadow="5px 5px 50px rgba(0, 0, 0, .2)">
          <Heading textAlign="center" mb={5}>
            會員登入
          </Heading>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel htmlFor="email">登入</FormLabel>
              <Input
                type="email"
                name="email"
                id="email"
                aria-describedby="password"
                ref={register({ required: true })}
              />
              {errors.username && <FormHelperText id="email-helper-text">必填</FormHelperText>}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">密碼</FormLabel>
              <Input
                type="password"
                name="password"
                id="password"
                aria-describedby="email-helper-text"
                ref={register({ required: true })}
              />
              {errors.password && <FormHelperText id="password">必填</FormHelperText>}
            </FormControl>
            <Button
              type="submit"
              bg="brand.500"
              color="white"
              _hover={{ bg: 'brand.700' }}
              _active={{ bg: 'brand.700', shadow: '3px 3px 0 #439D55' }}
            >
              登入
            </Button>
          </Stack>
        </Box>
      </form>
    </AnimateLoginBg>
  )
}

export default connect(null, { login })(LoginPage)

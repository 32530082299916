/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Box, Flex, RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb } from '@chakra-ui/react';
import convertNumber from 'utils/convertNumber';
import { useState } from 'react';
import getMinMax from 'utils/getMinMax';

const rangeSliderThumbStyling = {
	boxSize: '10px',
	_focus: {
		boxShadow: 'none',
		bg: 'red.300',
	},
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to

// ones that have values between the two
export function SliderColumnFilter({
	step = 1,
	column: { filterValue = [], preFilteredRows, setFilter, id, ...otherColumn },
}) {
	const [currentValues, setCurrentValues] = useState(null);
	const [min, max] = useMemo(() => {
		let mixMaxArray = getMinMax(preFilteredRows, id);

		if (currentValues) {
			let currentMin = currentValues[0];
			let currentMax = currentValues[1];
			if (mixMaxArray[0] && currentMin < mixMaxArray[0]) currentMin = mixMaxArray[0];
			if (mixMaxArray[1] && currentMax > mixMaxArray[1]) currentMax = mixMaxArray[1];
			setCurrentValues([currentMin, currentMax]);
		}

		return mixMaxArray;
	}, [id, preFilteredRows]);

	useEffect(() => {
		if (filterValue.length) return;
		setFilter([min, max]);
		setCurrentValues([min, max]);
	}, [filterValue]);

	function handleOnChange(values) {
		setFilter(values);
		setCurrentValues(values);
	}

	return (
		<Flex mx={3} mb={3}>
			{currentValues ? (
				<RangeSlider
					focusThumbOnChange={false}
					aria-label={`['min', 'max']`}
					value={currentValues}
					min={min}
					max={max}
					onChange={handleOnChange}
				>
					<RangeSliderTrack bg="gray.50">
						<RangeSliderFilledTrack bg="brand.400" />
					</RangeSliderTrack>
					<RangeSliderThumb
						{...rangeSliderThumbStyling}
						color="gray.300"
						sx={{
							'&[data-active]': {
								bg: 'gray.400',
								color: 'gray.600',
								zIndex: 2,
								'> div': {
									boxShadow: '2px 1px 5px rgba(0,0,0,.1)',
								},
							},
						}}
						index={0}
					>
						<Box
							p={1}
							bg="white"
							lineHeight={1}
							borderRadius={2}
							mt="35px"
							fontSize="12px"
							title={convertNumber(currentValues[0], false)}
						>
							{convertNumber(currentValues[0], false)}
						</Box>
					</RangeSliderThumb>
					<RangeSliderThumb
						{...rangeSliderThumbStyling}
						bg="brand.400"
						color="brand.400"
						sx={{
							'&[data-active]': {
								bg: 'brand.800',
								color: 'brand.800',
								zIndex: 2,
								'> div': {
									boxShadow: '2px 1px 5px rgba(0,0,0,.1)',
								},
							},
						}}
						index={1}
					>
						<Box
							p={1}
							bg="white"
							lineHeight={1}
							borderRadius={2}
							mt="35px"
							fontSize="12px"
							title={convertNumber(currentValues[1], false)}
						>
							{convertNumber(currentValues[1], false)}
						</Box>
					</RangeSliderThumb>
				</RangeSlider>
			) : null}
			{/* <Range
				values={filterValue}
				step={step}
				min={min}
				max={max}
				onChange={(values) => setFilter(values)}
				renderTrack={({ props, children }) => (
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						style={{
							...props.style,
							height: '20px',
							display: 'flex',
							width: '100%',
						}}
					>
						<div
							ref={props.ref}
							style={{
								height: '5px',
								width: '100%',
								borderRadius: '4px',
								background: getTrackBackground({
									values: filterValue,
									colors: ['#7EC88D', '#ccc'],
									min,
									max,
								}),
								alignSelf: 'center',
							}}
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ props, isDragged }) => (
					<div {...props} style={{ ...props.style }}>
						<div
							style={{
								color: props.key === 0 ? '#AAA' : '#7EC88D',
								fontSize: '12px',
								position: 'absolute',
								left: '50%',
								top: '14px',
								transform: 'translateX(-50%)',
							}}
						>
							{convertNumber(props['aria-valuenow'], '')}
						</div>
						<div
							style={{
								alignItems: 'center',
								backgroundColor: isDragged ? '#e57a7a' : props.key === 0 ? '#ffffff' : '#7EC88D',
								borderRadius: '4px',
								boxShadow: '0px 2px 6px #AAA',
								display: 'flex',
								height: '10px',
								justifyContent: 'center',
								width: '10px',
							}}
						></div>
					</div>
				)}
			/> */}
		</Flex>
	);
}

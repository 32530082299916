import React, { useEffect, useState } from 'react'
import Table from 'shared/Table/Table'
import EditStaff from 'pages/StaffPage/EditStaff'
import { Provider } from 'context/dataProvider'
import { getStaffList } from 'api/Staff'

export const StaffPage = (props) => {
  const [data, setData] = useState([])
  const [type] = useState('Staff')
  const [columns] = useState([
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: '專員名稱',
      accessor: 'name',
    },
    {
      Header: '編輯',
      accessor: 'edit',
      disableSortBy: true,
    },
  ])

  const fetchData = () => {
    getStaffList()
      .then(function (res) {
        setData(res.data.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const context = {
    data,
    setData,
    columns,
    type,
  }

  return (
    <Provider value={context}>
      <EditStaff />
      <Table />
    </Provider>
  )
}

import React, { useEffect, useState } from 'react';
import { Provider } from 'context/dataProvider';
import Table from 'shared/Table/Table';
import { getSeoList } from 'api/Seo';
import { Box, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { RiAddLine } from 'react-icons/ri';

export const SeoPage = () => {
	const [data, setData] = useState([]);
	const [type] = useState('Seo');
	const [columns] = useState([
		{
			Header: 'No',
			accessor: 'no',
		},
		{
			Header: 'SEO連結',
			accessor: 'url',
		},
		{
			Header: '標題',
			accessor: 'title',
			disableSortBy: true,
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getSeoList()
			.then(function (res) {
				// console.log(res.data)
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	// useEffect(() => {
	// 	console.log('data', data)
	// }, [data])

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Box textAlign="right" mb={4}>
				<Button as={Link} to="seo/add" colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					新增頁面SEO
				</Button>
			</Box>
			<Table />
		</Provider>
	);
};

import React from 'react'
import { Box, Button } from '@chakra-ui/react'
import { RiAddLine } from 'react-icons/ri'

const AddBlockButton = (props) => {
  return (
    <Button ml="auto" mb={4} colorScheme="brand" variant="solid" onClick={props.triggerModal}>
      <Box as={RiAddLine} mr={2} />
      新增區塊
    </Button>
  )
}

export default AddBlockButton

import React, { useContext } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import context from 'context/globalContext';

export const SidebarToggleBtn = () => {
	const { toggleSidebarOpen } = useContext(context);

	const sidebarHandler = () => {
		toggleSidebarOpen((prevState) => !prevState);
	};

	return <IconButton mr={2} isRound aria-label="開合選單" icon={<FaBars />} bg="white" onClick={sidebarHandler} />;
};

import React, { useEffect, useState, useContext, useRef } from 'react';
import { FaSave } from 'react-icons/fa';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Divider,
	Badge,
} from '@chakra-ui/react';
import globalContext from 'context/globalContext';
import { uploadImage } from 'api/Upload';
import { showPraise, updatePraise } from 'api/Praise';

export const EditPraisePage = (props) => {
	let history = useHistory();
	const { targetID } = useParams();
	const { REACT_APP_BASE_URL } = process.env;
	const { windowSize } = useContext(globalContext);
	const { register, handleSubmit, errors } = useForm();
	const toast = useToast();

	const banner_web_img_upload = useRef();
	const banner_mobile_img_upload = useRef();
	const praise_img1_upload = useRef();
	const praise_img2_upload = useRef();
	const praise_img3_upload = useRef();

	const [formData, setFormData] = useState({
		banner_web_img: null,
		banner_mobile_img: null,
		praise_img1: null,
		praise_img2: null,
		praise_img3: null,
	});

	useEffect(() => {
		if (targetID) {
			async function fetchData() {
				const { data } = await showPraise(targetID);
				setFormData(data.data);
			}

			fetchData();
		} else {
			console.log('new');
		}
	}, [targetID]);

	const onSubmit = (values) => {
		updatePraise(targetID, values)
			.then(function (res) {
				if (res.data.code === 200) {
					toast({
						title: '通知中心',
						description: '好評見證修改成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});
					history.push('/dashboard/praise');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const onChangePicture = (e, target) => {
		if (e !== null && e.target.files[0]) {
			const reader = new FileReader();
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'praise');
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});

					setFormData({
						...formData,
						[target]: data.data,
					});
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});
				}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const column = windowSize.width > 992 ? '2' : '1';
	// const column3 = windowSize.width > 1280 ? '3' : '1'

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					編輯好評見證
				</Heading>
				<Heading as="h3" size="lg">
					Banner
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns={column} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="banner_web_img" mb={1}>
								電腦版<Badge ml={'20px'}>1920*320(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="banner_web_img"
								ref={banner_web_img_upload}
								onChange={(e) => onChangePicture(e, 'banner_web_img')}
							/>
							<Input
								type="hidden"
								name="banner_web_img"
								defaultValue={formData.banner_web_img}
								ref={register({ required: true })}
							/>
							{formData.banner_web_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.banner_web_img}
											alt="好評見證首頁電腦版用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													banner_web_img: null,
												});

												banner_web_img_upload.current.value = null;
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.banner_web_img && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="banner_mobile_img" mb={1}>
								手機版<Badge ml={'20px'}>375*320(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="banner_mobile_img"
								ref={banner_mobile_img_upload}
								onChange={(e) => onChangePicture(e, 'banner_mobile_img')}
							/>
							<Input
								type="hidden"
								name="banner_mobile_img"
								defaultValue={formData.banner_mobile_img}
								ref={register({ required: true })}
							/>
							{formData.banner_mobile_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.banner_mobile_img}
											alt="好評見證首頁手機版用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													banner_mobile_img: null,
												});

												banner_mobile_img_upload.current.value = null;
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.banner_mobile_img && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={20} />
					<Heading as="h3" size="lg">
						破千網友好評見證
					</Heading>

					<SimpleGrid columns={column} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="praise_img1" mb={1}>
								圖片1<Badge ml={'20px'}>1000*1000(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="praise_img1_upload"
								ref={praise_img1_upload}
								onChange={(e) => onChangePicture(e, 'praise_img1')}
							/>
							<Input
								type="hidden"
								name="praise_img1"
								defaultValue={formData.praise_img1}
								ref={register({ required: true })}
							/>
							{formData.praise_img1 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.praise_img1}
											alt="院區據點首頁用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													praise_img1: null,
												});

												praise_img1_upload.current.value = null;
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.praise_img1 && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>

					<SimpleGrid columns={column} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="praise_img2" mb={1}>
								圖片2<Badge ml={'20px'}>1000*1000(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="praise_img2"
								ref={praise_img2_upload}
								onChange={(e) => onChangePicture(e, 'praise_img2')}
							/>
							<Input
								type="hidden"
								name="praise_img2"
								defaultValue={formData.praise_img2}
								ref={register({ required: true })}
							/>
							{formData.praise_img2 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.praise_img2}
											alt="院區據點首頁用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													praise_img2: null,
												});

												praise_img2_upload.current.value = null;
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.praise_img2 && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<SimpleGrid columns={column} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="praise_img3" mb={1}>
								圖片3<Badge ml={'20px'}>1000*1000(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="praise_img3"
								ref={praise_img3_upload}
								onChange={(e) => onChangePicture(e, 'praise_img3')}
							/>
							<Input
								type="hidden"
								name="praise_img3"
								defaultValue={formData.praise_img3}
								ref={register({ required: true })}
							/>
							{formData.praise_img3 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.praise_img3}
											alt="院區據點首頁用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													praise_img3: null,
												});

												praise_img3_upload.current.value = null;
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.praise_img3 && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '新增'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	);
};

import React, { useContext } from 'react'
import styled from 'styled-components'
import context from 'context/globalContext'
import { Box } from '@chakra-ui/react'

const StyledMain = styled(Box)`
	height: 100vh;
	transition: width 0.2s;
	position: relative;
	overflow: auto;
`

export const Main = ({ children }) => {
	const { sidebarOpen } = useContext(context)

	return (
		<StyledMain
			bg="gray.50"
			p={3}
			boxSizing="border-box"
			ml={sidebarOpen ? '250px' : '0'}
			width={{ sm: sidebarOpen ? '100vw' : 'auto', md: 'auto' }}
			transition="margin-left .2s"
		>
			{children}
		</StyledMain>
	)
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { customTheme } from 'common/customTheme';

const CustomNavLink = styled(NavLink)`
	&.active {
		color: ${customTheme.colors.brand[500]};
	}
`;

export const SidebarMenuItem = (props) => {
	const { item } = props;

	return (
		<Accordion allowMultiple>
			<AccordionItem border="none">
				<AccordionButton
					_expanded={{ bg: 'gray.100' }}
					_focus={{ outline: 0, boxShadow: 'none' }}
					_hover={{ bg: 'gray.100' }}
					alignItems="stretch"
					bg={'transparent'}
					borderRadius="0"
					borderWidth="0"
					display="flex"
					height="auto"
					p="0"
					transition="all 0.2s"
					width="100%"
					fontWeight="bold"
				>
					<Box as={CustomNavLink} to={item.router} p="3" flex="1" textAlign="left">
						{item.title}
					</Box>
					{item.children !== undefined ? (
						<Box alignItems="center" display="flex" flexBasis="40px" justifyContent="center">
							<AccordionIcon />
						</Box>
					) : null}
				</AccordionButton>

				{item.children !== undefined ? (
					<AccordionPanel p={0}>
						{item.children.map((childItem, i) => {
							return (
								<CustomNavLink to={childItem.router} key={i}>
									<Button
										_expanded={{ bg: 'gray.100' }}
										_focus={{ outline: 0, boxShadow: 'none' }}
										_hover={{ bg: 'gray.100' }}
										bg="gray.50"
										borderRadius="0"
										borderWidth="0"
										item={childItem}
										p="3"
										transition="all 0.2s"
										width="100%"
										alignContent="stretch"
										display="flex"
										justifyContent="space-between"
									>
										{childItem.title}
									</Button>
								</CustomNavLink>
							);
						})}
					</AccordionPanel>
				) : null}
			</AccordionItem>
		</Accordion>
	);
};

import React, { useEffect, useState, useContext } from 'react'
import { FaSave } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Textarea,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Badge,
} from '@chakra-ui/react'
import globalContext from 'context/globalContext'
import { uploadImage } from 'api/Upload'
import { addDoctor, showDoctor, updateDoctor } from 'api/Doctor'

export const EditDoctorPage = (props) => {
	let history = useHistory()
	const { targetID } = useParams()
	const { REACT_APP_BASE_URL } = process.env
	const { windowSize } = useContext(globalContext)
	const { register, handleSubmit, errors } = useForm()
	const toast = useToast()
	const [formData, setFormData] = useState({
		name: '',
		about: '',
		job: '',
		title: '',
		education: '',
		experience: '',
		specialize: '',
		index_web_img: '',
		inside_web_img: '',
	})

	useEffect(() => {
		if (targetID) {
			async function fetchData() {
				const { data } = await showDoctor(targetID)
				setFormData(data.data)
			}

			fetchData()
		}
	}, [targetID])

	const onSubmit = (values) => {
		if (targetID) {
			updateDoctor(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '醫師修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/doctors/list')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			addDoctor(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '醫師建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/doctors/list')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const onChangePicture = (e, target) => {
		if (e.target.files[0]) {
			const reader = new FileReader()
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'doctor')
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})

					switch (target) {
						case 'indexWebImg':
							setFormData({
								...formData,
								index_web_img: data.data,
							})
							return

						case 'insideWebImg':
							setFormData({
								...formData,
								inside_web_img: data.data,
							})
							return

						default:
							return
					}
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				}
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const column = windowSize.width > 640 ? '2' : '1'

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					{targetID ? '修改' : '新增'}醫師資訊
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns={column} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="name" mb={1}>
								姓名
							</FormLabel>
							<Input
								id="name"
								name="name"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.name}
							/>
							{errors.name && <FormHelperText>請填入姓名</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="title" mb={1}>
								職稱
							</FormLabel>
							<Input
								id="title"
								name="title"
								type="text"
								defaultValue={formData.title}
								ref={register({ required: true })}
							/>
							{errors.title && <FormHelperText>請填入職稱</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="index_web_img_upload" mb={1}>
								醫師列表用圖 <Badge ml={'20px'}>400x400(1 MB)</Badge>
							</FormLabel>
							<Input type="file" name="index_web_img_upload" onChange={(e) => onChangePicture(e, 'indexWebImg')} />
							<Input
								type="hidden"
								name="index_web_img"
								defaultValue={formData.index_web_img}
								ref={register({ required: true })}
							/>
							{formData.index_web_img ? (
								<HStack alignItems="flex-end">
									<Box w="100%">
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.index_web_img}
											alt="醫師列表用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													index_web_img: null,
												})
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.index_web_img && <FormHelperText>請指定一張圖片為醫師列表用圖</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="inside_web_img_upload" mb={1}>
								醫師資訊用圖<Badge ml={'20px'}>800x1237(1 MB)</Badge>
							</FormLabel>
							<Input type="file" name="inside_web_img_upload" onChange={(e) => onChangePicture(e, 'insideWebImg')} />
							<Input
								type="hidden"
								name="inside_web_img"
								defaultValue={formData.inside_web_img}
								ref={register({ required: true })}
							/>
							{formData.inside_web_img ? (
								<HStack alignItems="flex-end">
									<Box w="100%">
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.inside_web_img}
											alt="醫師資訊用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													inside_web_img: null,
												})
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.inside_web_img && <FormHelperText>請指定一張圖片為醫師資訊用圖</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="about" mb={1}>
								關於
							</FormLabel>
							<Textarea id="about" name="about" defaultValue={formData.about} ref={register({ required: true })} />
							{errors.about && <FormHelperText>請輸入關於</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="job" mb={1}>
								現任
							</FormLabel>
							<Textarea id="job" name="job" defaultValue={formData.job} ref={register({ required: true })} />
							{errors.job && <FormHelperText>請輸入現任</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="education" mb={1}>
								學歷
							</FormLabel>
							<Textarea
								id="education"
								name="education"
								defaultValue={formData.education}
								ref={register({ required: true })}
							/>
							{errors.education && <FormHelperText>請輸入學歷</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="experience" mb={1}>
								經歷
							</FormLabel>
							<Textarea
								id="experience"
								name="experience"
								defaultValue={formData.experience}
								ref={register({ required: true })}
							/>
							{errors.experience && <FormHelperText>請輸入經歷</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="specialize" mb={1}>
								專長
							</FormLabel>
							<Textarea
								id="specialize"
								name="specialize"
								defaultValue={formData.specialize}
								ref={register({ required: true })}
							/>
							{errors.specialize && <FormHelperText>請輸入專長</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '新增'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	)
}

import React, { useState, useEffect } from 'react';
import Table from 'shared/Table/Table';
import { Provider as DataProvider } from 'context/dataProvider';
import SurveyFeatures from './SurveyFeatures';
import { getSurveyList } from 'api/Survey';
import { getStaffList } from 'api/Staff';
import { getDoctorList } from 'api/Doctor';

export const SurveyPage = () => {
	const [originalData, setOriginalData] = useState([]);
	const [data, setData] = useState([]);
	const [type] = useState('Survey');
	const [filteredRowsData, setFilteredRowsData] = useState([]);
	const [reacttablefilter, setReacttablefilter] = useState(false);
	const [columns, setColumn] = useState([]);
	const [isFinished, setIsFinished] = useState(false);
	const [sortBy, setSortBy] = useState([
		{
			id: 'id',
			desc: true,
		},
	]);
	const [staffs, setStaff] = useState([]);
	const [doctors, setDoctor] = useState([]);

	const fetchSurvey = async () => {
		const { data } = await getSurveyList();
		setOriginalData(data.data);
		setData(data.data.filter((item) => item.status === (isFinished ? 1 : 0)));
	};

	const fetchStaff = async () => {
		const { data } = await getStaffList();
		setStaff(data.data);
	};

	const fetchDoctor = async () => {
		const { data } = await getDoctorList();
		setDoctor(data.data);
	};

	useEffect(() => {
		fetchStaff();
		fetchDoctor();
	}, []);

	useEffect(() => {
		if (staffs && doctors) {
			fetchSurvey();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [staffs, doctors]);

	const context = {
		originalData,
		setOriginalData,
		data,
		setData,
		columns,
		setColumn,
		isFinished,
		setIsFinished,
		sortBy,
		setSortBy,
		type,
		staffs,
		doctors,
		fetchSurvey,
		filteredRowsData,
		setFilteredRowsData,
		reacttablefilter,
		setReacttablefilter,
	};

	return (
		<DataProvider value={context}>
			<SurveyFeatures />
			<Table />
		</DataProvider>
	);
};

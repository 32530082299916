import React, { useState, useEffect, useContext } from 'react'
import { RiUserAddLine } from 'react-icons/ri'
import { FaSave } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { IoMdClose } from 'react-icons/io'
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	useDisclosure,
	useToast,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { createUser, updateUser, showUser, getUserList } from 'api/User'
import globalContext from 'context/globalContext'
import context from 'context/dataProvider'

const EditUser = (props) => {
	const { setData } = useContext(context)
	const { userType } = useContext(globalContext)
	const targetID = props.targetID || null
	const { register, handleSubmit, errors } = useForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()

	const [formData, setFormData] = useState({
		name: null,
		email: null,
		type: null,
		password: null,
	})

	const fetchList = async () => {
		const { data } = await getUserList()
		setData(data.data)
	}

	const onSubmit = (values) => {
		if (targetID) {
			updateUser(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '專員修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			createUser(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '專員建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	useEffect(() => {
		if (isOpen && targetID) {
			async function fetchData() {
				const { data } = await showUser(targetID)
				setFormData(data.data)
			}

			fetchData()
		} else {
			setFormData({
				name: null,
				email: null,
				type: null,
				password: null,
			})
		}
	}, [isOpen, targetID])

	return (
		<Flex display={targetID ? 'inline' : 'flex'}>
			{targetID ? (
				<IconButton onClick={onOpen} icon={<FaRegEdit />} />
			) : (
				<Button onClick={onOpen} ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiUserAddLine} mr={2} />
					新增管理者
				</Button>
			)}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent borderRadius="10px">
					<ModalHeader>{targetID ? '修改' : '新增'} 管理者</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onSubmit)}>
							{targetID ? (
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" htmlFor="id" mb={1}>
											ID
										</FormLabel>
										<Box flex="3">{formData.id}</Box>
									</Flex>
								</FormControl>
							) : null}
							<FormControl mb={3}>
								<FormLabel htmlFor="name" mb={1}>
									姓名
								</FormLabel>
								<Input
									id="name"
									name="name"
									type="text"
									autoComplete="new-name"
									defaultValue={formData.name}
									ref={register({ required: true })}
								/>
								{errors.name && <FormHelperText>請填入姓名</FormHelperText>}
							</FormControl>
							<FormControl mb={3}>
								<FormLabel htmlFor="email" mb={1}>
									信箱
								</FormLabel>
								<Input
									id="email"
									name="email"
									type="email"
									autoComplete="new-email"
									defaultValue={formData.email}
									ref={register({ required: true })}
								/>
								{errors.mail && <FormHelperText>請輸入信箱</FormHelperText>}
							</FormControl>
							{userType === 1 ? (
								<FormControl mb={3}>
									<FormLabel htmlFor="type" mb={1}>
										管理身份
									</FormLabel>
									<Select
										id="type"
										name="type"
										value={String(formData.type)}
										onChange={(e) => setFormData({ ...formData, type: e.target.value })}
										ref={register({ required: true })}
									>
										<option value="">-- 選擇管理身份 --</option>
										<option value="0">諮詢表單管理者</option>
										<option value="1">網站管理者</option>
									</Select>
									{errors.type && <FormHelperText>請輸入管理身份</FormHelperText>}
								</FormControl>
							) : null}
							<FormControl>
								<FormLabel htmlFor="password" mb={1}>
									密碼
								</FormLabel>
								<Input
									id="password"
									name="password"
									type="password"
									autoComplete="new-password"
									ref={register({ required: true })}
								/>
								{errors.password && <FormHelperText>請輸入密碼</FormHelperText>}
							</FormControl>
							<Flex mt={4} pb={2} justify="flex-end">
								<Button variant="ghost" onClick={onClose} mr={3}>
									<Box as={IoMdClose} mr={2} />
									取消
								</Button>
								<Button colorScheme="brand" type="submit">
									<Box as={FaSave} mr={2} />
									{targetID ? '修改' : '新增'}
								</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export default EditUser

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { useParams, useHistory } from 'react-router-dom';
import {
	useToast,
	Box,
	Button,
	Flex,
	Heading,
	Divider,
	Select,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormControl,
} from '@chakra-ui/react';
import { createCategory, showCategory, updateCategory } from 'api/Service/Category';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddBlockButton from 'pages/ServicePage/AddBlockButton';
import BlockA from 'pages/ServicePage/Blocks/BlockA';
import BlockB from 'pages/ServicePage/Blocks/BlockB';
import BlockC from 'pages/ServicePage/Blocks/BlockC';
import BlockD from 'pages/ServicePage/Blocks/BlockD';
import BlockE from 'pages/ServicePage/Blocks/BlockE';

export const EditServiceCategoryPage = (props) => {
	let history = useHistory();
	const { targetID } = useParams();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [serviceBlocksList, setServiceBlocksList] = useState([]);
	const [selectOption, setSelectOption] = useState('');
	const [isDisableAddBlock, setDisableAddBlock] = useState(true);
	const [isEditBlock, setEditBlockStatus] = useState(false);
	const [isSelectedCategory, setSeletedCategoryStatus] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [currentCategory, setCurrentCategory] = useState('');
	const serviceCategory = [
		{ category: '0', title: '男性雄性禿' },
		{ category: '1', title: '女性雄性禿' },
		{ category: '2', title: '髮際線調整術' },
		{ category: '3', title: '植眉/鬍/鬢角' },
		{ category: '4', title: '無針擬真繡髮' },
	];

	const Item = styled.div`
		padding: 8px;
		border-radius: 3px;
		margin-bottom: 8px;
	`;

	useEffect(() => {
		if (targetID) {
			async function fetchData() {
				const { data } = await showCategory(targetID);
				let categoryList = data.data.content.split(',');
				let tmpArr = [];
				categoryList.forEach((el, idx) => {
					let obj = {};
					obj.id = idx.toString();
					obj.type = el.split('-')[0];
					obj.queryID = el;
					tmpArr.push(obj);
				});
				setServiceBlocksList(tmpArr);
				setCurrentCategory(data.data.category);
			}
			fetchData();
		}
	}, [targetID]);

	const addBlock = () => {
		let arr = Array.from(serviceBlocksList);
		let lastIdx = parseInt(arr.length, 10);

		setServiceBlocksList([...arr, { id: lastIdx.toString(), type: selectOption, queryID: '' }]);
		console.log(serviceBlocksList);
		onClose();
		toast({
			title: '通知中心',
			description: '版型區塊新增成功',
			status: 'success',
			duration: 2000,
			isClosable: true,
			position: 'top-right',
		});
		setDisableAddBlock(true);
		setEditBlockStatus(true);
	};

	const handleSelectChange = (evt) => {
		let target = evt.currentTarget.value;
		setDisableAddBlock(false);
		setSelectOption(target);
	};

	const handleSelectCategoryChange = (evt) => {
		let target = evt.currentTarget.value;
		setSeletedCategoryStatus(true);
		setSelectedCategory(target);
	};

	const saveBlock = (targetID, blockID) => {
		setEditBlockStatus(false);
		let arr = Array.from(serviceBlocksList);
		arr[targetID].queryID = blockID;
		setServiceBlocksList(arr);
	};

	const deleteBlock = (targetID) => {
		let arr = Array.from(serviceBlocksList);
		arr.filter((obj) => obj.id !== targetID);
		setServiceBlocksList(arr.filter((obj) => obj.id !== targetID));
		setEditBlockStatus(false);
	};

	const renderBlock = (targetID, type, queryID) => {
		switch (type) {
			case 'a':
				return <BlockA id={targetID} queryID={queryID} save={saveBlock} delete={deleteBlock} mode={isEditBlock} />;
			case 'b':
				return <BlockB id={targetID} queryID={queryID} save={saveBlock} delete={deleteBlock} mode={isEditBlock} />;
			case 'c':
				return <BlockC id={targetID} queryID={queryID} save={saveBlock} delete={deleteBlock} mode={isEditBlock} />;
			case 'd':
				return <BlockD id={targetID} queryID={queryID} save={saveBlock} delete={deleteBlock} mode={isEditBlock} />;
			case 'e':
				return <BlockE id={targetID} queryID={queryID} save={saveBlock} delete={deleteBlock} mode={isEditBlock} />;
			default:
				console.log('error');
		}
	};

	const showCurrentCategory = (category) => {
		switch (category) {
			case '0':
				return '男性雄性禿';
			case '1':
				return '女性雄性禿';
			case '2':
				return '髮際線調整術';
			case '3':
				return '髮際線調整';
			case '4':
				return '無針擬真繡髮';
			default:
				break;
		}
	};

	const modifiedBlocks = () => {
		let arr = Array.from(serviceBlocksList);
		if (arr.length === 0) {
			toast({
				title: '通知中心',
				description: '尚未新增任何區塊',
				status: 'warning',
				duration: 2000,
				isClosable: true,
				position: 'top-right',
			});
		} else {
			let data = {
				category: selectedCategory,
				content: '',
			};
			arr.forEach((ele, idx) => {
				if (ele.queryID) {
					if (idx === 0) {
						data.content += ele.queryID;
					} else {
						data.content += ',' + ele.queryID;
					}
				}
			});
			if (targetID) {
				data.category = currentCategory;
				updateCategory(targetID, data)
					.then(function (res) {
						console.log(res);
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '區塊編輯成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							});
							history.push('/dashboard/service/categories');
						}
					})
					.catch(function (error) {
						if (error.response) {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						} else if (error.request) {
							console.log(error.request);
						} else {
							console.log('Error', error.message);
						}
					});
			} else {
				createCategory(data)
					.then(function (res) {
						console.log(res);
						if (res.data.code === 200) {
							toast({
								title: '通知中心',
								description: '區塊編輯成功',
								status: 'success',
								duration: 2000,
								isClosable: true,
								position: 'top-right',
							});
							history.push('/dashboard/service/categories');
						}
					})
					.catch(function (error) {
						if (error.response) {
							console.log(error.response.data);
							console.log(error.response.status);
							console.log(error.response.headers);
						} else if (error.request) {
							console.log(error.request);
						} else {
							console.log('Error', error.message);
						}
					});
			}
		}
	};

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Flex justifyContent="space-between" alignItems="center">
					<Heading as="h2" size="xl" mt={5} mb={8}>
						{targetID ? '修改' : '新增'}服務項目
					</Heading>
					<AddBlockButton triggerModal={onOpen} />
				</Flex>

				<FormControl mb={5}>
					<Heading as="h3" size="md" mt={5} mb={8}>
						{showCurrentCategory(currentCategory)}
					</Heading>
					{targetID ? null : (
						<Select placeholder="選擇分類" onChange={handleSelectCategoryChange}>
							{serviceCategory.map((type, i) => {
								const { category, title } = type;
								return (
									<option value={category} key={i}>
										{title}
									</option>
								);
							})}
						</Select>
					)}
				</FormControl>
				<DragDropContext
					onDragEnd={(result) => {
						const { source, destination } = result;
						if (!destination) {
							return;
						}

						let arr = Array.from(serviceBlocksList);
						const [remove] = arr.splice(source.index, 1);
						arr.splice(destination.index, 0, remove);
						setServiceBlocksList(arr);
					}}
				>
					<Droppable droppableId="id">
						{(provided) => (
							<Box ref={provided.innerRef} {...provided.droppableProps}>
								{serviceBlocksList.map((block, idx) => (
									<Draggable draggableId={block.id} key={block.id} index={idx}>
										{(p) => (
											<Item ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps} key={block.id}>
												{renderBlock(block.id, block.type, block.queryID)}
											</Item>
										)}
									</Draggable>
								))}
							</Box>
						)}
					</Droppable>
				</DragDropContext>

				<Divider my={10} />
				<Flex mt={4} pb={2} justify="flex-end">
					<Button
						colorScheme="brand"
						onClick={() => {
							modifiedBlocks();
						}}
					>
						<Box as={FaSave} mr={2} />
						{targetID ? '修改' : '新增'}
					</Button>
				</Flex>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>請選擇欲新增的版型</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Select
							placeholder="選擇版型"
							value={selectOption}
							onChange={(evt) => {
								handleSelectChange(evt);
							}}
						>
							<option value="a">版型一：1X2基本區塊</option>
							<option value="b">版型二：2X2基本區塊</option>
							<option value="c">版型三：2X3基本區塊</option>
							<option value="d">版型四：3X2二分區塊</option>
							<option value="e">版型五：2X2二分區塊</option>
						</Select>
					</ModalBody>
					<ModalFooter>
						<Button variant="ghost" onClick={onClose} mr={3}>
							<Box as={IoMdClose} mr={2} />
							取消
						</Button>
						<Button
							colorScheme="brand"
							mr={3}
							onClick={addBlock}
							isDisabled={isDisableAddBlock || isEditBlock || !isSelectedCategory}
						>
							<Box as={FaSave} mr={2} />
							新增
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
};

import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	FormHelperText,
	Heading,
	Input,
	SimpleGrid,
	Divider,
	HStack,
	Image,
	useToast,
	Badge,
} from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
import { AiTwotoneDelete } from 'react-icons/ai';
import { uploadImage } from 'api/Upload';
import { createBlock, getBlock, updateBlock } from 'api/Service/Block';
import styled from 'styled-components';
import Collapse from 'shared/Collapse';

const StyledForm = styled.form`
	opacity: 0;
	transition: opacity 1s;

	&.show {
		opacity: 1;
	}
`;

const BlockB = (props) => {
	const [show, setShow] = useState(false);
	const { targetID } = useParams();
	const { register, handleSubmit, errors } = useForm();
	const { REACT_APP_BASE_URL } = process.env;
	const img1_upload = useRef();
	const img2_upload = useRef();
	const img3_upload = useRef();
	const img4_upload = useRef();
	const toast = useToast();
	const [formData, setFormData] = useState({
		type: null,
		title: null,
		img1: null,
		img2: null,
		img3: null,
		img4: null,
		sub_title1: null,
		sub_title2: null,
		sub_title3: null,
		sub_title4: null,
		description1: null,
		description2: null,
		description3: null,
		description4: null,
		url: null,
	});

	const handleToggle = (status) => {
		console.log(status);
		if (!status && props.queryID) {
			async function fetchData() {
				const { data } = await getBlock(props.queryID);
				setFormData(data.data);
			}

			fetchData();
		}

		setShow(!show);
	};
	const onChangePicture = (e, target) => {
		if (e !== null && e.target.files[0]) {
			const reader = new FileReader();
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'block');
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});

					setFormData({
						...formData,
						[target]: data.data,
					});
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					});
				}
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onSubmit = (values) => {
		let blockID = '';
		if (targetID && !props.mode) {
			updateBlock(props.queryID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '區塊修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
						setShow(!show);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		} else {
			createBlock(values)
				.then(function (res) {
					if (res.data.code === 200) {
						blockID = res.data.data;
						toast({
							title: '通知中心',
							description: '區塊建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						});
						props.save(props.id, blockID);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};

	return (
		<Flex border="1px" borderColor="gray.200" bg="#ffffff" borderRadius={10} p={5}>
			<Box w="100%">
				<Heading
					as="h3"
					size="xl"
					mt={5}
					mb={show ? 8 : 5}
					onClick={() => {
						handleToggle(show);
					}}
				>
					版型二：2X2基本區塊
				</Heading>

				<Collapse mt={4} isOpen={show}>
					<StyledForm onSubmit={handleSubmit(onSubmit)} className={show ? 'show' : null}>
						<FormControl>
							<FormLabel htmlFor="title" mb={1}>
								標題
							</FormLabel>
							<Input
								id="title"
								name="title"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.title}
							/>
							{errors.name && <FormHelperText>請填入區塊標題</FormHelperText>}
						</FormControl>
						<Divider my={10} />
						<SimpleGrid columns={2} spacingX={5} spacingY={5}>
							<FormControl mt={2}>
								<FormLabel htmlFor="img1_upload" mb={1}>
									區塊圖片一<Badge ml={'20px'}>1200x900(1 MB)</Badge>
								</FormLabel>
								<Input type="file" name="img1_upload" ref={img1_upload} onChange={(e) => onChangePicture(e, 'img1')} />
								<Input type="hidden" name="img1" defaultValue={formData.img1} ref={register({ required: true })} />
								{formData.img1 ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image size="200px" objectFit="cover" src={REACT_APP_BASE_URL + formData.img1} alt="區塊圖片一" />
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														img1: null,
													});

													img1_upload.current.value = null;
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.img1 && <FormHelperText>請指定一張圖片為區塊圖片一用圖</FormHelperText>}
							</FormControl>
							<FormControl mt={2}>
								<FormLabel htmlFor="img2_upload" mb={1}>
									區塊圖片二<Badge ml={'20px'}>1200x900(1 MB)</Badge>
								</FormLabel>
								<Input type="file" name="img2_upload" ref={img2_upload} onChange={(e) => onChangePicture(e, 'img2')} />
								<Input type="hidden" name="img2" defaultValue={formData.img2} ref={register({ required: true })} />
								{formData.img2 ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image size="200px" objectFit="cover" src={REACT_APP_BASE_URL + formData.img2} alt="區塊圖片二" />
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														img2: null,
													});

													img2_upload.current.value = null;
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.img2 && <FormHelperText>請指定一張圖片為區塊圖片一用圖</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="sub_title1" mb={1}>
									小標一
								</FormLabel>
								<Input
									id="sub_title1"
									name="sub_title1"
									type="text"
									ref={register({ required: true })}
									defaultValue={formData.sub_title1}
								/>
								{errors.name && <FormHelperText>請填入小標</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="sub_title2" mb={1}>
									小標二
								</FormLabel>
								<Input
									id="sub_title2"
									name="sub_title2"
									type="text"
									ref={register({ required: true })}
									defaultValue={formData.sub_title2}
								/>
								{errors.name && <FormHelperText>請填入小標</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="description1" mb={1}>
									描述一
								</FormLabel>
								<Input
									id="description1"
									name="description1"
									type="text"
									ref={register}
									defaultValue={formData.description1}
								/>
								{errors.name && <FormHelperText>請填入描述一</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="description2" mb={1}>
									描述二
								</FormLabel>
								<Input
									id="description2"
									name="description2"
									type="text"
									ref={register}
									defaultValue={formData.description2}
								/>
								{errors.name && <FormHelperText>請填入描述二</FormHelperText>}
							</FormControl>
							<FormControl mt={2}>
								<FormLabel htmlFor="img3_upload" mb={1}>
									區塊圖片三<Badge ml={'20px'}>1200x900(1 MB)</Badge>
								</FormLabel>
								<Input type="file" name="img3_upload" ref={img3_upload} onChange={(e) => onChangePicture(e, 'img3')} />
								<Input type="hidden" name="img3" defaultValue={formData.img3} ref={register({ required: true })} />
								{formData.img3 ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image size="200px" objectFit="cover" src={REACT_APP_BASE_URL + formData.img3} alt="區塊圖片一" />
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														img3: null,
													});

													img3_upload.current.value = null;
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.img3 && <FormHelperText>請指定一張圖片為區塊圖片三用圖</FormHelperText>}
							</FormControl>
							<FormControl mt={2}>
								<FormLabel htmlFor="img4_upload" mb={1}>
									區塊圖片四<Badge>1200x900(1 MB)</Badge>
								</FormLabel>
								<Input type="file" name="img4_upload" ref={img4_upload} onChange={(e) => onChangePicture(e, 'img4')} />
								<Input type="hidden" name="img4" defaultValue={formData.img4} ref={register({ required: true })} />
								{formData.img4 ? (
									<HStack alignItems="flex-end">
										<Box w={'100%'}>
											<Image size="200px" objectFit="cover" src={REACT_APP_BASE_URL + formData.img4} alt="區塊圖片二" />
										</Box>
										<Box>
											<Button
												colorScheme="brand"
												onClick={() => {
													setFormData({
														...formData,
														img4: null,
													});

													img4_upload.current.value = null;
												}}
											>
												刪除圖片
											</Button>
										</Box>
									</HStack>
								) : null}
								{errors.img4 && <FormHelperText>請指定一張圖片為區塊圖片四用圖</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="sub_title3" mb={1}>
									小標三
								</FormLabel>
								<Input
									id="sub_title3"
									name="sub_title3"
									type="text"
									ref={register({ required: true })}
									defaultValue={formData.sub_title3}
								/>
								{errors.name && <FormHelperText>請填入小標</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="sub_title4" mb={1}>
									小標四
								</FormLabel>
								<Input
									id="sub_title4"
									name="sub_title4"
									type="text"
									ref={register({ required: true })}
									defaultValue={formData.sub_title4}
								/>
								{errors.name && <FormHelperText>請填入小標</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="description3" mb={1}>
									描述三
								</FormLabel>
								<Input
									id="description3"
									name="description3"
									type="text"
									ref={register}
									defaultValue={formData.description3}
								/>
								{errors.name && <FormHelperText>請填入描述三</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="description4" mb={1}>
									描述四
								</FormLabel>
								<Input
									id="description4"
									name="description4"
									type="text"
									ref={register}
									defaultValue={formData.description4}
								/>
								{errors.name && <FormHelperText>請填入描述四</FormHelperText>}
							</FormControl>
						</SimpleGrid>
						<FormControl mt={2}>
							<FormLabel htmlFor="url" mb={1}>
								案例連結
							</FormLabel>
							<Input id="url" name="url" type="text" ref={register} defaultValue={formData.url} />
							{errors.name && <FormHelperText>請填入案例連結</FormHelperText>}
							<Input type="hidden" name="type" id="type" defaultValue={'b'} ref={register} />
						</FormControl>
						<Divider my={10} />
						<Flex mt={4} pb={2} justify="flex-end">
							<Button
								mr={2}
								variant="ghost"
								colorScheme="red"
								onClick={() => {
									props.delete(props.id);
								}}
							>
								<Box as={AiTwotoneDelete} mr={2} />
								刪除
							</Button>
							<Button colorScheme="brand" type="submit">
								<Box as={FaSave} mr={2} />
								儲存
							</Button>
						</Flex>
					</StyledForm>
				</Collapse>
			</Box>
		</Flex>
	);
};

export default BlockB;

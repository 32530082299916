import React, { useEffect, useState, useContext, useRef } from 'react'
import { FaSave } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Divider,
	Select,
	Checkbox,
	Badge,
} from '@chakra-ui/react'
import globalContext from 'context/globalContext'
import { uploadImage } from 'api/Upload'
import { createKnowledgePost, showKnowledgePost, updateKnowledgePost } from 'api/Knowledge'
import Editor from 'shared/Editor/Editor'
import { hairKnowledgeCategory } from './hairKnowledgeCategory'

export const EditHairKnowledgePosts = () => {
	let history = useHistory()
	const { targetID } = useParams()
	const { REACT_APP_BASE_URL } = process.env
	const { windowSize } = useContext(globalContext)
	const { register, handleSubmit, errors } = useForm()
	const toast = useToast()
	const web_img_upload = useRef()
	const [textAreaValue, setTextAreaValue] = useState()
	const handleTextAreaChange = (newTextAreaValue) => {
		// console.log('新內容', newTextAreaValue)
		return setTextAreaValue(() => newTextAreaValue)
	}

	const [formData, setFormData] = useState({
		title: null,
		type: null,
		featured: null,
		web_img: null,
		content: '',
	})

	const [isFeatured, setFeatured] = useState(false)

	// TODO: cancel all subscriptions and asynchronous tasks
	useEffect(() => {
		if (targetID) {
			console.log('edit post')
			async function fetchData() {
				const { data } = await showKnowledgePost(targetID)
				setFormData(data.data)
			}
			fetchData()
		} else {
			console.log('new post')
		}
	}, [targetID])

	const onSubmit = (values) => {
		// FIXME: 硬塞textAreaValue..不優..
		values.content = textAreaValue
		console.log('values', JSON.stringify(values))
		if (targetID) {
			updateKnowledgePost(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '分類文章修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/knowledge/post')
					}

					console.log('result', res.data)
				})
				.catch(function (error) {
					toast({
						title: '通知中心',
						description: '分類文章修改失敗',
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				})
		} else {
			createKnowledgePost(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '分類文章建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/knowledge/post')
					}
				})
				.catch(function (error) {
					toast({
						title: '通知中心',
						description: '分類文章建立失敗',
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				})
		}
	}

	const onChangePicture = (e, target) => {
		if (e !== null && e.target.files[0]) {
			const reader = new FileReader()
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'knowledgePost')
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})

					setFormData({
						...formData,
						[target]: data.data,
					})
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				}
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const column = windowSize.width > 992 ? '2' : '1'
	// const column3 = windowSize.width > 1280 ? '3' : '1'

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					{targetID ? '修改' : '新增'}選單分類文章
				</Heading>
				<Heading as="h3" size="lg" mb={8}>
					分類文章
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns={column} spacingX={5} spacingY={5}>
						<FormControl mb={5}>
							<FormLabel htmlFor="title" mb={1}>
								標題
							</FormLabel>
							<Input
								id="title"
								name="title"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.title}
							/>
							{errors.title && <FormHelperText>請填入標題名稱</FormHelperText>}
						</FormControl>
						<FormControl defaultValue={formData.type}>
							<FormLabel htmlFor="type">文章分類 </FormLabel>
							<Box flex="2">
								<Select
									id="type"
									name="type"
									ref={register({ required: true })}
									value={String(formData.type)}
									onChange={(e) => setFormData({ ...formData, type: e.target.value })}
								>
									{hairKnowledgeCategory.map((post, i) => {
										const { type, name } = post
										return (
											<option value={type} key={i}>
												{name}
											</option>
										)
									})}
								</Select>
							</Box>
						</FormControl>
						<FormControl>
							<HStack spacing={10}>
								<Checkbox colorScheme="green" value={isFeatured} onChange={() => setFeatured(!isFeatured)}>
									精選文章
								</Checkbox>
							</HStack>
						</FormControl>

						<FormControl>
							<Select
								placeholder="請選擇1~8精選順序"
								name="featured"
								isDisabled={!isFeatured}
								value={String(formData.featured)}
								onChange={(e) => setFormData({ ...formData, featured: e.target.value })}
								ref={register()}
							>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel htmlFor="web_img" mb={1}>
								縮圖<Badge ml={'20px'}>900x600(1 MB)</Badge>
							</FormLabel>
							<Input type="file" name="web_img" ref={web_img_upload} onChange={(e) => onChangePicture(e, 'web_img')} />
							<Input
								type="hidden"
								name="web_img"
								defaultValue={formData.web_img === '/upload/preset.jpg' ? '' : formData.web_img}
								ref={register}
							/>
							{formData.web_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image size="200px" objectFit="cover" src={REACT_APP_BASE_URL + formData.web_img} alt="圖片" />
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													web_img: null,
												})

												web_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.web_img && <FormHelperText>請指定一張圖片為縮圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<FormControl>
						<FormLabel>編輯文章</FormLabel>
						<Input type="hidden" name="content" ref={register()} defaultValue={formData.content} />
						<Editor
							id="content"
							name="content"
							imgFolder="knowledge-post"
							onChange={handleTextAreaChange}
							value={String(formData.content)}
						/>
					</FormControl>

					<Divider my={10} />
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '發佈'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	)
}

import Axios from 'axios'

export const uploadImage = (source, target) => {
	const { REACT_APP_API_URL } = process.env

	const data = {
		image: source,
		target,
	}

	return Axios.post(REACT_APP_API_URL + '/upload/img', data)
}

import React, { useContext } from 'react';
import styled from 'styled-components';
import context from 'context/globalContext';
import { Box } from '@chakra-ui/react';
import { SidebarMenuItem } from './SidebarMenuItem';

const StyledSidebar = styled(Box)`
	height: 100vh;
	overflow: auto;
	position: fixed;
	width: 250px;
`;
const StyledLogoImg = styled.img`
	margin: auto;
`;

export const Sidebar = () => {
	const { sidebarOpen, userType } = useContext(context);

	const menuList = [
		{
			title: '管理者資訊',
			router: '/dashboard/member',
			admin: false,
		},
		{
			title: '專員資訊',
			router: '/dashboard/staff',
			admin: false,
		},
		{
			title: '首頁 Banner',
			router: '/dashboard/index',
		},
		{
			title: '醫師資訊',
			router: '/dashboard/doctors/list',
			children: [
				{
					title: '新增醫師資訊',
					router: '/dashboard/doctors/add',
				},
				{
					title: '醫師資訊列表',
					router: '/dashboard/doctors/list',
				},
				{
					title: '頁面Banner',
					router: '/dashboard/doctors/banner',
				},
			],
		},
		{
			title: '院區據點',
			router: '/dashboard/clinic/list',
			children: [
				{
					title: '新增院區據點',
					router: '/dashboard/clinic/add',
				},
				{
					title: '院區據點列表',
					router: '/dashboard/clinic/list',
				},
			],
		},
		{
			title: '服務項目',
			router: '/dashboard/service/categories',
			children: [
				{
					title: 'Banner 列表',
					router: '/dashboard/service/banners',
				},
				{
					title: '分類列表',
					router: '/dashboard/service/categories',
				},
			],
		},
		{
			title: '生髮知識',
			router: '/dashboard/knowledge/post',
			children: [
				{
					title: 'Banner 列表',
					router: '/dashboard/knowledge/banner',
				},
				{
					title: '文章列表',
					router: '/dashboard/knowledge/post',
				},
			],
		},
		// {
		// 	title: '最新消息',
		// 	router: '/dashboard/news/list',
		// 	children: [
		// 		{
		// 			title: '新增最新消息',
		// 			router: '/dashboard/news/add',
		// 		},
		// 		{
		// 			title: '最新消息列表',
		// 			router: '/dashboard/news/list',
		// 		},
		// 	],
		// },
		{
			title: '植髮諮詢表單',
			router: '/dashboard/survey',
			admin: false,
		},
		{
			title: '植髮預估試算',
			router: '/dashboard/calculator',
		},
		{
			title: '紋髮預約表單',
			router: '/dashboard/needlelessSMP',
			admin: false,
		},
		{
			title: '紋髮預估試算',
			router: '/dashboard/calculator-needlelessSMP',
			admin: false,
		},
		{
			title: '好評見證',
			router: '/dashboard/praise/list',
			children: [
				{
					title: '首頁資訊編輯',
					router: '/dashboard/praise/banner/edit/1',
				},
				{
					title: '影片列表',
					router: '/dashboard/praise/list',
				},
			],
		},
		{
			title: 'SEO 設定',
			router: '/dashboard/seo',
		},
	];

	return (
		<StyledSidebar bg="white" boxShadow="xl" zIndex={1} left={sidebarOpen ? '0' : '-250px'} transition="left .2s">
			<Box mb={4} textAlign="center">
				<StyledLogoImg src={`/assets/images/logo_light.png`} alt="LOGO" />
			</Box>
			{menuList.map((item, i) => {
				let menuItem = null;
				// INFO: userType: 0 (guest); 1 (admin)
				if (userType === 1) {
					menuItem = <SidebarMenuItem key={i} item={item} />;
				} else {
					if (item.admin === false) {
						menuItem = <SidebarMenuItem key={i} item={item} />;
					}
				}
				return menuItem;
			})}
		</StyledSidebar>
	);
};

import Axios from 'axios'
import { getToken } from 'utils/auth'

export const getSeoList = () => {
	const { REACT_APP_API_URL } = process.env
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	}

	return Axios.get(REACT_APP_API_URL + '/seos', config)
}
export const createSeo = (data) => {
	const { REACT_APP_API_URL } = process.env
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	}

	return Axios.post(REACT_APP_API_URL + '/seos', data, config)
}

export const showSeo = (id) => {
	const { REACT_APP_API_URL } = process.env
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	}

	return Axios.get(REACT_APP_API_URL + '/seos/' + id, config)
}

export const updateSeo = (id, data) => {
	const { REACT_APP_API_URL } = process.env
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	}

	return Axios.put(REACT_APP_API_URL + '/seos/' + id, data, config)
}
export const deleteSeo = (id) => {
	const { REACT_APP_API_URL } = process.env
	let config = {
		headers: { Authorization: `Bearer ${getToken()}`, 'Content-Type': `application/json` },
	}

	return Axios.delete(REACT_APP_API_URL + '/seos/' + id, config)
}

import { Input } from '@chakra-ui/react';
import React from 'react';

// Define a default UI for filtering
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
	// const count = preFilteredRows.length;

	return (
		<Input
			size="sm"
			value={filterValue || ''}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
		/>
	);
}

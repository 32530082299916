import React, { useEffect, useState } from 'react'
import Table from 'shared/Table/Table'
import EditBanner from 'pages/IndexPage/EditBanner'
import { Provider } from 'context/dataProvider'
import { getBannerList } from 'api/Banner'

export const IndexPage = (props) => {
  const [data, setData] = useState([])
  const [type] = useState('Index')
  const [columns] = useState([
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: '編輯',
      accessor: 'edit',
      disableSortBy: true,
    },
  ])

  const fetchData = () => {
    getBannerList()
      .then(function (res) {
        setData(res.data.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const context = {
    data,
    setData,
    columns,
    type,
  }

  return (
    <Provider value={context}>
      {data.length < 5 ? <EditBanner /> : null}
      <Table />
    </Provider>
  )
}

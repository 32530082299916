import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Radio,
	RadioGroup,
	Select,
	Stack,
	HStack,
	Textarea,
} from '@chakra-ui/react';

export const SurveyForm = (props) => {
	const { register, setValue } = useFormContext();
	const { data } = props;

	let now = new Date();
	now = `${now.getFullYear()}-${now.getMonth() < 10 ? '0' + now.getMonth() : now.getMonth()}-${
		now.getDate() < 10 ? '0' + now.getDate() : now.getDate()
	}`;

	const servicers = [
		{ id: 0, name: '言蓁' },
		{ id: 1, name: '宸安' },
		{ id: 2, name: '育心' },
		{ id: 3, name: '芝琪' },
		{ id: 4, name: '孟萱' },
		{ id: 5, name: '虹樺' },
		{ id: 6, name: '雅姿' },
		{ id: 7, name: '宇萱' },
		{ id: 8, name: '鈺柔' },
		{ id: 9, name: '曉娟' },
		{ id: 10, name: '香伶' },
		{ id: 11, name: '小畢' },
	];

	const doctors = [
		{ id: 0, name: '朱冠州' },
		{ id: 1, name: '方孝俤' },
		{ id: 2, name: '董則聖' },
		{ id: 3, name: '黃于甄' },
		{ id: 4, name: '賴衍翰' },
		{ id: 5, name: '楊立' },
	];

	const vaildReasons = [
		{ id: 0, name: '植髮' },
		{ id: 1, name: '髮際線' },
		{ id: 2, name: '植鬍' },
		{ id: 3, name: '植眉' },
		{ id: 4, name: '落髮' },
		{ id: 5, name: '其它' },
	];

	return (
		<Stack spacing={3}>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="serialNumber">
						序號
					</FormLabel>
					<Box flex="2">{data.serialNumber}</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="sheetNumber">
						表單編號
					</FormLabel>
					<Box flex="2">{data.sheetNumber}</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactConfirm">
						聯繫確認
					</FormLabel>
					<Box flex="2">
						<RadioGroup name="contactConfirm">
							<HStack>
								<Radio colorScheme="brand" value="0" ref={register({ required: true })}>
									否
								</Radio>
								<Radio colorScheme="brand" value="1" ref={register({ required: true })}>
									是
								</Radio>
							</HStack>
						</RadioGroup>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactServicer">
						聯繫者
					</FormLabel>
					<Box flex="2">
						<Select placeholder="請選擇專員" name="contactServicer" ref={register({ required: true })}>
							{servicers.map((person, i) => {
								const { id, name } = person;
								return (
									<option value={id} key={i}>
										{name}
									</option>
								);
							})}
						</Select>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactDoctor">
						諮詢醫師
					</FormLabel>
					<Box flex="2">
						<Select placeholder="請選擇醫師" name="contactDoctor" ref={register({ required: true })}>
							{doctors.map((person, i) => {
								const { id, name } = person;
								return (
									<option value={id} key={i}>
										{name} 醫師
									</option>
								);
							})}
						</Select>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactCame">
						到店確認
					</FormLabel>
					<Box flex="2">
						<RadioGroup name="contactCame">
							<HStack>
								<Radio colorScheme="brand" value="0" ref={register({ required: true })}>
									否
								</Radio>
								<Radio colorScheme="brand" value="1" ref={register({ required: true })}>
									是
								</Radio>
							</HStack>
						</RadioGroup>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactDeal">
						成交確認
					</FormLabel>
					<Box flex="2">
						<RadioGroup name="contactDeal">
							<HStack>
								<Radio colorScheme="brand" value="0" ref={register({ required: true })}>
									否
								</Radio>
								<Radio colorScheme="brand" value="1" ref={register({ required: true })}>
									是
								</Radio>
							</HStack>
						</RadioGroup>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactDealTime">
						成交日
					</FormLabel>
					<Box flex="2">
						<HStack>
							<Input type="date" name="contactDealTime" ref={register({ required: true })} />
							<Button
								colorScheme="brand"
								onClick={() =>
									setValue('contactDealTime', now, {
										shouldValidate: true,
										shouldDirty: true,
									})
								}
							>
								今天
							</Button>
						</HStack>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactDealMoney">
						成交金額
					</FormLabel>
					<Box flex="2">
						<div className="currency">
							<Input type="number" name="contactDealMoney" ref={register({ required: true })} />
						</div>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactInvaildReason">
						無效原因
					</FormLabel>
					<Box flex="2">
						<Input type="text" name="contactInvaildReason" ref={register({ required: true })} />
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactVaildReason">
						有效原因
					</FormLabel>
					<Box flex="2">
						<Select placeholder="請選擇有效原因" name="contactVaildReason" ref={register({ required: true })}>
							{vaildReasons.map((reason, i) => {
								const { id, name } = reason;
								return (
									<option value={id} key={i}>
										{name}
									</option>
								);
							})}
						</Select>
					</Box>
				</Flex>
			</FormControl>
			<FormControl>
				<Flex alignItems="center">
					<FormLabel flex="1" fontWeight="bold" htmlFor="contactNote">
						備註
					</FormLabel>
					<Box flex="2">
						<Textarea name="contactNote" ref={register} />
					</Box>
				</Flex>
			</FormControl>
		</Stack>
	);
};

import React, { useMemo, useState, useEffect, useContext } from 'react'
import { useSticky } from 'react-table-sticky'
import { useTable, useBlockLayout, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table'
import { Flex, Box, Icon } from '@chakra-ui/react'
import { BiMinus, BiCaretDown, BiCaretUp } from 'react-icons/bi'
import dataContext from 'context/dataProvider'
import { StyledTable } from './StyledTable'
import { Pagination } from './Pagination'
import { GlobalFilter } from './Filters/GlobalFilter'
import { DefaultColumnFilter } from './Filters/DefaultColumnFilter'
import { fuzzyTextFilterFn } from './Filters/fuzzyTextFilterFn'

// Our table component
export default function FilteredTable({ columns, data }) {
	const { type, sortBy, setFilteredRowsData } = useContext(dataContext)
	const [sortList, setSortList] = useState([])

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			},
		}),
		[],
	)

	const defaultColumn = useMemo(
		() => ({
			minWidth: 0,
			width: 140,
			maxWidth: 400,
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[],
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		state: { pageIndex, pageSize },
		// visibleColumns,
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			disableSortRemove: true,
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: {
				pageIndex: 0,
				pageSize: 10,
				sortBy: sortList,
			},
		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		useSticky,
		useSortBy,
		usePagination,
		useBlockLayout,
	)

	useEffect(() => {
		if (sortBy) {
			setSortList(sortBy)
		}
	}, [sortBy])

	useEffect(() => {
		if (rows.length && setFilteredRowsData) {
			let processedRowsData = []
			rows.map((item) => {
				return processedRowsData.push(item.values)
			})
			setFilteredRowsData(processedRowsData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows])

	return (
		<StyledTable>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				type={type}
			/>
			<Box overflowX="auto" mb={3}>
				<div {...getTableProps()} className="table sticky">
					<div className="header">
						{headerGroups.map((headerGroup) => (
							<div {...headerGroup.getHeaderGroupProps()} className="tr">
								{headerGroup.headers.map((column) => (
									<div {...column.getHeaderProps()} className="th">
										{column.render('Header')}
										<span {...column.getSortByToggleProps()}>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<Icon as={BiCaretDown} boxSize="10px" />
												) : (
													<Icon as={BiCaretUp} boxSize="10px" />
												)
											) : (
												<Icon as={BiMinus} boxSize="10px" />
											)}
										</span>
										{/* Render the columns filter UI */}
										<div>{column.filter ? column.render('Filter') : null}</div>
									</div>
								))}
							</div>
						))}
					</div>
					<div {...getTableBodyProps()} className="body">
						{data.length ? (
							page.map((row, i) => {
								prepareRow(row)
								return (
									<div {...row.getRowProps()} className="tr">
										{row.cells.map((cell) => {
											return (
												<div {...cell.getCellProps()} className="td">
													{cell.render('Cell')}
												</div>
											)
										})}
									</div>
								)
							})
						) : (
							<Box textAlign="center" p={6}>
								NO DATA
							</Box>
						)}
					</div>
				</div>
			</Box>
			<Flex justify="space-between" wrap="wrap">
				<Box>共有 {rows.length} 筆資料</Box>
				<Pagination
					gotoPage={gotoPage}
					previousPage={previousPage}
					nextPage={nextPage}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					pageCount={pageCount}
					pageOptions={pageOptions}
					pageIndex={pageIndex}
					pageSize={pageSize}
					setPageSize={setPageSize}
				/>
			</Flex>
		</StyledTable>
	)
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import Table from 'shared/Table/Table';
import { Provider } from 'context/dataProvider';
import { getPraiseList } from 'api/Praise';
import moment from 'moment';

export const PraisePage = () => {
	const [data, setData] = useState([]);
	const [type] = useState('Praise');
	const [columns] = useState([
		{
			Header: '編號',
			accessor: 'id',
		},
		{
			Header: '影片標題',
			accessor: 'title',
		},
		{
			Header: '選單分類',
			accessor: 'category',
			Cell: ({ cell: { value } }) => {
				if (value === 1) {
					value = '雄性禿';
				} else if (value === 2) {
					value = '髮際線調整術';
				} else {
					value = '植眉|植鬍|鬢角';
				}
				return value;
			},
		},
		{
			Header: '發布日期',
			accessor: 'created_at',
			Cell: ({ cell: { value } }) => {
				return moment(value).format('YYYY-MM-DD');
			},
		},
		{
			Header: '編輯',
			accessor: 'edit',
			disableSortBy: true,
		},
	]);

	const fetchData = () => {
		getPraiseList()
			.then(function (res) {
				setData(res.data.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const context = {
		data,
		setData,
		columns,
		type,
	};

	return (
		<Provider value={context}>
			<Flex flexDirection="flex-end">
				<Button as={Link} to="add" ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiAddLine} mr={2} />
					新增影片
				</Button>
			</Flex>
			<Table />
		</Provider>
	);
};

import React, { useState, useEffect, useContext } from 'react'
import { RiUserAddLine } from 'react-icons/ri'
import { FaSave } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { IoMdClose } from 'react-icons/io'
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	IconButton,
	FormHelperText,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { createStaff, updateStaff, showStaff, getStaffList } from 'api/Staff'
import context from 'context/dataProvider'

const EditStaff = (props) => {
	const { setData } = useContext(context)
	const targetID = props.targetID || null
	const { register, handleSubmit, errors } = useForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()

	const [formData, setFormData] = useState({
		name: null,
	})

	const fetchList = async () => {
		const { data } = await getStaffList()
		setData(data.data)
	}

	const onSubmit = (values) => {
		if (targetID) {
			updateStaff(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '專員修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			createStaff(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '專員建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	useEffect(() => {
		if (isOpen && targetID) {
			async function fetchData() {
				const { data } = await showStaff(targetID)
				setFormData(data.data)
			}

			fetchData()
		} else {
			setFormData({ name: null })
		}
	}, [isOpen, targetID])

	return (
		<Flex display={targetID ? 'inline' : 'flex'}>
			{targetID ? (
				<IconButton onClick={onOpen} icon={<FaRegEdit />} />
			) : (
				<Button onClick={onOpen} ml="auto" mb={4} colorScheme="brand" variant="solid">
					<Box as={RiUserAddLine} mr={2} />
					新增專員
				</Button>
			)}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent borderRadius="10px">
					<ModalHeader>{targetID ? '修改' : '新增'} 專員</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<form onSubmit={handleSubmit(onSubmit)}>
							<FormControl>
								<FormLabel htmlFor="name" mb={1}>
									姓名
								</FormLabel>
								<Input type="text" id="name" name="name" defaultValue={formData.name} ref={register} />
								{errors.name && <FormHelperText>請填入姓名</FormHelperText>}
							</FormControl>
							<Flex mt={4} pb={2} justify="flex-end">
								<Button variant="ghost" onClick={onClose} mr={3}>
									<Box as={IoMdClose} mr={2} />
									取消
								</Button>
								<Button colorScheme="brand" type="submit">
									<Box as={FaSave} mr={2} />
									{targetID ? '修改' : '新增'}
								</Button>
							</Flex>
						</form>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export default EditStaff

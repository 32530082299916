import React, { useState, useEffect, useContext, Fragment } from 'react'
import moment from 'moment'
import { FaSave, FaCheck, FaTimes } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { IoMdClose } from 'react-icons/io'
import {
	Button,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Radio,
	RadioGroup,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	IconButton,
	FormHelperText,
	Select,
	Textarea,
	HStack,
} from '@chakra-ui/react'
import { FaRegEdit } from 'react-icons/fa'
import { update, show, getList } from 'api/NeedlelessSMP'
import context from 'context/dataProvider'

const EditNeedlelessSMP = (props) => {
	const { setOriginalData, staffs, doctors, isFinished } = useContext(context)
	const targetID = props.targetID || null
	const { register, handleSubmit, errors } = useForm()
	const [formContact, setFormContact] = useState('')
	const [formConfirm, setFormConfirm] = useState('')
	const [formDeal, setFormDeal] = useState('')
	const [formDealDateInput, setFormDealDateInput] = useState('')
	const { isOpen, onOpen, onClose } = useDisclosure()
	const toast = useToast()

	const [formData, setFormData] = useState({
		status: null,
		contact: null,
		deal: null,
		deal_amount: null,
		confirm: null,
		contact_member: null,
		location: null,
		contact_doctor_id: null,
		invalid_reason: null,
		valid_reason_items_id: null,
		dcdc_notes: null,
	})

	const fetchList = async () => {
		const { data } = await getList()
		setOriginalData(data.data)
	}

	const onSubmit = (values) => {
		if (targetID) {
			update(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '表單修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						onClose()
						fetchList()
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const updateStatus = (status) => {
		if (targetID) {
			update(targetID, { status })
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: (status === 0 ? '已退回' : '已略過') + '表單',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						fetchList()
					} else {
						toast({
							title: '通知中心',
							description: '請稍後再試',
							status: 'danger',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	useEffect(() => {
		if (isOpen && targetID) {
			async function fetchData() {
				const { data } = await show(targetID)
				setFormData(data.data[0])
				setFormContact(String(data.data[0].contact))
				setFormConfirm(String(data.data[0].confirm))
				setFormDeal(String(data.data[0].deal))
				if (data.data[0].deal_date) {
					setFormDealDateInput(moment(data.data[0].deal_date * 1000).format('yyyy-MM-DD'))
				}
			}

			fetchData()
		} else {
			setFormData({ name: null })
		}
	}, [isOpen, targetID])

	useEffect(() => {
		setFormData({ ...formData, contact: parseInt(formContact) })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formContact])

	useEffect(() => {
		setFormData({ ...formData, confirm: parseInt(formConfirm) })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formConfirm])

	useEffect(() => {
		setFormData({ ...formData, deal: parseInt(formDeal) })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formDeal])

	useEffect(() => {
		let date = new Date(formDealDateInput).getTime() / 1000
		setFormData({ ...formData, deal_date: date })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formDealDateInput])

	return (
		<Flex display="flex">
			<Fragment>
				<IconButton onClick={onOpen} icon={<FaRegEdit />} mr={2} />
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent borderRadius="10px">
						<ModalHeader>{targetID ? '修改' : '新增'}表單</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<form onSubmit={handleSubmit(onSubmit)}>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="id">
											表單編號
										</FormLabel>
										<Box flex="2">
											{formData.id}
											<Input type="hidden" name="status" defaultValue={formData.status} ref={register} />
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="contact">
											是否聯繫
										</FormLabel>
										<Box flex="2">
											<RadioGroup name="contact" onChange={setFormContact} value={formContact}>
												<HStack>
													<Radio colorScheme="brand" value="0" ref={register}>
														否
													</Radio>
													<Radio colorScheme="brand" value="1" ref={register}>
														是
													</Radio>
												</HStack>
											</RadioGroup>
										</Box>
									</Flex>
									{errors.contact && <FormHelperText>請選擇是否聯繫</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="contact_member">
											聯繫者
										</FormLabel>
										<Box flex="2">
											<Select
												placeholder="請選擇專員"
												id="contact_member"
												name="contact_member"
												value={String(formData.contact_member)}
												onChange={(e) => setFormData({ ...formData, contact_member: e.target.value })}
												ref={register}
											>
												{isOpen && targetID
													? staffs.map((person, i) => {
															const { name } = person
															return (
																<option value={name} key={i}>
																	{name}
																</option>
															)
													  })
													: null}
											</Select>
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="location">
											地區
										</FormLabel>
										<Box flex="2">
											<Select
												placeholder="請選擇地區"
												id="location"
												name="location"
												ref={register}
												value={String(formData.location)}
												onChange={(e) => setFormData({ ...formData, location: e.target.value })}
											>
												<option value="台北">台北</option>
												<option value="新竹">新竹</option>
												<option value="台中">台中</option>
												<option value="高雄">高雄</option>
											</Select>
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="contact_doctor_id">
											諮詢醫師
										</FormLabel>
										<Box flex="2">
											<Select
												placeholder="請選擇醫師"
												id="contact_doctor_id"
												name="contact_doctor_id"
												ref={register}
												value={String(formData.contact_doctor_id)}
												onChange={(e) => setFormData({ ...formData, contact_doctor_id: e.target.value })}
											>
												{isOpen && targetID
													? doctors.map((person, i) => {
															const { name, title } = person
															return (
																<option value={name + title} key={i}>
																	{name}
																	{title}
																</option>
															)
													  })
													: null}
											</Select>
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="confirm">
											到店確認
										</FormLabel>
										<Box flex="2">
											<RadioGroup name="confirm" onChange={setFormConfirm} value={formConfirm} ref={register}>
												<HStack>
													<Radio colorScheme="brand" value="0" ref={register}>
														否
													</Radio>
													<Radio colorScheme="brand" value="1" ref={register}>
														是
													</Radio>
												</HStack>
											</RadioGroup>
										</Box>
									</Flex>
									{errors.confirm && <FormHelperText>請選擇是否到店</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="deal">
											成交確認
										</FormLabel>
										<Box flex="2">
											<RadioGroup name="deal" onChange={setFormDeal} value={formDeal}>
												<HStack>
													<Radio colorScheme="brand" value="0" ref={register}>
														否
													</Radio>
													<Radio colorScheme="brand" value="1" ref={register}>
														是
													</Radio>
												</HStack>
											</RadioGroup>
										</Box>
									</Flex>
									{errors.deal && <FormHelperText>請選擇是否成功</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="deal_date_input">
											成交日期
										</FormLabel>
										<Box flex="2">
											<Input
												type="date"
												id="deal_date_input"
												name="deal_date_input"
												value={formDealDateInput}
												onChange={(e) => {
													setFormDealDateInput(e.target.value)
												}}
											/>
											<Input name="deal_date" type="hidden" value={formData.deal_date || ''} readOnly ref={register} />
										</Box>
									</Flex>
									{errors.deal_date && <FormHelperText>請填寫成交日期</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="deal_amount">
											成交金額
										</FormLabel>
										<Box flex="2">
											<Input
												type="number"
												id="deal_amount"
												name="deal_amount"
												defaultValue={formData.deal_amount}
												ref={register}
											/>
										</Box>
									</Flex>
									{errors.deal_amount && <FormHelperText>請填寫成交金額</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="invalid_reason">
											無效原因
										</FormLabel>
										<Box flex="2">
											<Input
												type="text"
												id="invalid_reason"
												name="invalid_reason"
												defaultValue={formData.invalid_reason}
												ref={register}
											/>
										</Box>
									</Flex>
									{errors.invalid_reason && <FormHelperText>請填寫無效原因</FormHelperText>}
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="valid_reason_items_id">
											有效原因
										</FormLabel>
										<Box flex="2">
											<Select
												placeholder="請選擇有效原因"
												id="valid_reason_items_id"
												name="valid_reason_items_id"
												ref={register}
												value={String(formData.valid_reason_items_id)}
												onChange={(e) => setFormData({ ...formData, valid_reason_items_id: e.target.value })}
											>
												<option value="0">植髮</option>
												<option value="1">髮際線</option>
												<option value="2">植鬍</option>
												<option value="3">植眉</option>
												<option value="4">落髮</option>
												<option value="5">其它</option>
											</Select>
										</Box>
									</Flex>
								</FormControl>
								<FormControl mb={3}>
									<Flex alignItems="center">
										<FormLabel flex="1" fontWeight="bold" htmlFor="dcdc_notes">
											DCDC 備註
										</FormLabel>
										<Box flex="2">
											<Textarea
												placeholder=""
												id="dcdc_notes"
												name="dcdc_notes"
												defaultValue={formData.dcdc_notes}
												ref={register}
											/>
										</Box>
									</Flex>
								</FormControl>
								<Flex mt={4} pb={2} justify="flex-end">
									<Button variant="ghost" onClick={onClose} mr={3}>
										<Box as={IoMdClose} mr={2} />
										取消
									</Button>
									<Button
										colorScheme="gray"
										type="submit"
										mr={3}
										onClick={(e) => setFormData({ ...formData, status: isFinished ? 1 : 0 })}
									>
										<Box as={FaSave} mr={2} />
										儲存
									</Button>
									{isFinished ? (
										<Button colorScheme="red" type="submit" onClick={(e) => setFormData({ ...formData, status: 0 })}>
											<Box as={FaTimes} mr={2} />
											退回
										</Button>
									) : (
										<Button colorScheme="brand" type="submit" onClick={(e) => setFormData({ ...formData, status: 1 })}>
											<Box as={FaCheck} mr={2} />
											提交完成
										</Button>
									)}
								</Flex>
							</form>
						</ModalBody>
					</ModalContent>
				</Modal>
				{isFinished ? (
					<Button ml="auto" colorScheme="red" variant="solid" onClick={() => updateStatus(0)}>
						退回
					</Button>
				) : (
					<Button ml="auto" colorScheme="brand" variant="solid" onClick={() => updateStatus(1)}>
						略過
					</Button>
				)}
			</Fragment>
		</Flex>
	)
}

export default EditNeedlelessSMP

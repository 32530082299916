import React, { useEffect, useState, useContext, useRef } from 'react'
import { FaSave } from 'react-icons/fa'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Image,
	useToast,
	FormControl,
	FormLabel,
	Box,
	Input,
	FormHelperText,
	Textarea,
	Button,
	SimpleGrid,
	Flex,
	Heading,
	HStack,
	Divider,
	Badge,
} from '@chakra-ui/react'
import globalContext from 'context/globalContext'
import { uploadImage } from 'api/Upload'
import { addClinic, showClinic, updateClinic } from 'api/Clinic'

export const EditClinicPage = (props) => {
	let history = useHistory()
	const { targetID } = useParams()
	const { REACT_APP_BASE_URL } = process.env
	const { windowSize } = useContext(globalContext)
	const { register, handleSubmit, errors } = useForm()
	const toast = useToast()

	const stronghold_index_img_upload = useRef()
	const stronghold_list_img_upload = useRef()
	const stronghold_inside_img1_upload = useRef()
	const stronghold_inside_img2_upload = useRef()
	const stronghold_inside_img3_upload = useRef()
	const outpatient_time_web_img_upload = useRef()
	const outpatient_time_mobile_img_upload = useRef()

	const [formData, setFormData] = useState({
		name: null,
		address: null,
		phone: null,
		business_hours: null,
		facebook: null,
		line: null,
		high_speed_rail: null,
		mrt: null,
		bus: null,
		stronghold_index_img: null,
		stronghold_list_img: null,
		stronghold_inside_img1: null,
		stronghold_inside_img2: null,
		stronghold_inside_img3: null,
		outpatient_time_web_img: null,
		outpatient_time_mobile_img: null,
	})

	useEffect(() => {
		if (targetID) {
			async function fetchData() {
				const { data } = await showClinic(targetID)
				setFormData(data.data)
			}

			fetchData()
		}
	}, [targetID])

	const onSubmit = (values) => {
		console.log(values)
		if (targetID) {
			updateClinic(targetID, values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '院區據點修改成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/clinic/list')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		} else {
			addClinic(values)
				.then(function (res) {
					if (res.data.code === 200) {
						toast({
							title: '通知中心',
							description: '院區據點建立成功',
							status: 'success',
							duration: 2000,
							isClosable: true,
							position: 'top-right',
						})
						history.push('/dashboard/clinic/list')
					}
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	const onChangePicture = (e, target) => {
		if (e !== null && e.target.files[0]) {
			const reader = new FileReader()
			reader.addEventListener('load', async () => {
				const { data } = await uploadImage(reader.result, 'stronghold')
				if (data.code === 200) {
					toast({
						title: '通知中心',
						description: '圖片上傳成功',
						status: 'success',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})

					setFormData({
						...formData,
						[target]: data.data,
					})
				} else {
					toast({
						title: '通知中心',
						description: data.message,
						status: 'error',
						duration: 2000,
						isClosable: true,
						position: 'top-right',
					})
				}
			})
			reader.readAsDataURL(e.target.files[0])
		}
	}

	const column = windowSize.width > 992 ? '2' : '1'
	const column3 = windowSize.width > 1280 ? '3' : '1'

	return (
		<Flex bg="#ffffff" borderRadius={10} p={5} justifyContent="center">
			<Box w="100%" maxWidth="1000px">
				<Heading as="h2" size="xl" mt={5} mb={8}>
					{targetID ? '修改' : '新增'}院區據點
				</Heading>
				<form onSubmit={handleSubmit(onSubmit)}>
					<SimpleGrid columns={column} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="name" mb={1}>
								據點名稱
							</FormLabel>
							<Input
								id="name"
								name="name"
								type="text"
								ref={register({ required: true })}
								defaultValue={formData.name}
							/>
							{errors.name && <FormHelperText>請填入據點名稱</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="address" mb={1}>
								地址
							</FormLabel>
							<Input id="address" name="address" type="text" defaultValue={formData.address} ref={register} />
							{errors.address && <FormHelperText>請填入地址</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="phone" mb={1}>
								電話
							</FormLabel>
							<Input id="phone" name="phone" type="text" defaultValue={formData.phone} ref={register} />
							{errors.phone && <FormHelperText>請填入電話</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="business_hours" mb={1}>
								營業時間
							</FormLabel>
							<Input
								id="business_hours"
								name="business_hours"
								type="text"
								defaultValue={formData.business_hours}
								ref={register}
							/>
							{errors.business_hours && <FormHelperText>請填入營業時間</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="facebook" mb={1}>
								Facebook帳號
							</FormLabel>
							<Input id="facebook" name="facebook" type="text" defaultValue={formData.facebook} ref={register} />
							{errors.facebook && <FormHelperText>請填入Facebook帳號</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="line" mb={1}>
								LINE帳號
							</FormLabel>
							<Input id="line" name="line" type="text" defaultValue={formData.line} ref={register} />
							{errors.line && <FormHelperText>請填入LINE帳號</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="keyword" mb={1}>
								Google Map 分享關鍵字
							</FormLabel>
							<Input id="keyword" name="keyword" type="text" defaultValue={formData.keyword} ref={register} />
							{errors.keyword && <FormHelperText>請填入Google Map </FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<Heading as="h3" size="lg">
						公共運輸
					</Heading>
					<SimpleGrid columns={1} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="high_speed_rail" mb={1}>
								高鐵
							</FormLabel>
							<Textarea
								id="high_speed_rail"
								name="high_speed_rail"
								type="text"
								defaultValue={formData.high_speed_rail}
								ref={register}
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="mrt" mb={1}>
								捷運
							</FormLabel>
							<Textarea id="mrt" name="mrt" type="text" defaultValue={formData.mrt} ref={register} />
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="bus" mb={1}>
								公車
							</FormLabel>
							<Textarea id="bus" name="bus" type="text" defaultValue={formData.bus} ref={register} />
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<SimpleGrid columns={column} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="stronghold_index_img_upload" mb={1}>
								院區據點首頁用圖 <Badge ml={'20px'}>576x358(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="stronghold_index_img_upload"
								ref={stronghold_index_img_upload}
								onChange={(e) => onChangePicture(e, 'stronghold_index_img')}
							/>
							<Input
								type="hidden"
								name="stronghold_index_img"
								defaultValue={formData.stronghold_index_img}
								ref={register({ required: true })}
							/>
							{formData.stronghold_index_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.stronghold_index_img}
											alt="院區據點首頁用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													stronghold_index_img: null,
												})

												stronghold_index_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.stronghold_index_img && <FormHelperText>請指定一張圖片為院區據點首頁用圖</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="stronghold_list_img_upload" mb={1}>
								院區據點清單用圖 <Badge ml={'20px'}>1024x628(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="stronghold_list_img_upload"
								ref={stronghold_list_img_upload}
								onChange={(e) => onChangePicture(e, 'stronghold_list_img')}
							/>
							<Input
								type="hidden"
								name="stronghold_list_img"
								defaultValue={formData.stronghold_list_img}
								ref={register({ required: true })}
							/>
							{formData.stronghold_list_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.stronghold_list_img}
											alt="院區據點清單用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													stronghold_list_img: null,
												})

												stronghold_list_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.stronghold_list_img && <FormHelperText>請指定一張圖片為院區據點滑撥圖片1</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<SimpleGrid columns={column3} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="stronghold_inside_img1_upload" mb={1}>
								院區據點滑撥圖片1 <Badge ml={'20px'}>1024x628(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="stronghold_inside_img1_upload"
								ref={stronghold_inside_img1_upload}
								onChange={(e) => onChangePicture(e, 'stronghold_inside_img1')}
							/>
							<Input
								type="hidden"
								name="stronghold_inside_img1"
								defaultValue={formData.stronghold_inside_img1}
								ref={register({ required: true })}
							/>
							{formData.stronghold_inside_img1 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.stronghold_inside_img1}
											alt="院區據點滑撥圖片1"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													stronghold_inside_img1: null,
												})

												stronghold_inside_img1_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.stronghold_inside_img1 && <FormHelperText>請指定一張圖片為院區據點滑撥圖片1</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="stronghold_inside_img2_upload" mb={1}>
								院區據點滑撥圖片2 <Badge ml={'20px'}>1024x628(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="stronghold_inside_img2_upload"
								ref={stronghold_inside_img2_upload}
								onChange={(e) => onChangePicture(e, 'stronghold_inside_img2')}
							/>
							<Input
								type="hidden"
								name="stronghold_inside_img2"
								defaultValue={formData.stronghold_inside_img2}
								ref={register({ required: true })}
							/>
							{formData.stronghold_inside_img2 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.stronghold_inside_img2}
											alt="院區據點滑撥圖片2"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													stronghold_inside_img2: null,
												})

												stronghold_inside_img2_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.stronghold_inside_img2 && <FormHelperText>請指定一張圖片為院區據點滑撥圖片2</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="stronghold_inside_img3_upload" mb={1}>
								院區據點滑撥圖片3 <Badge ml={'20px'}>1024x628(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="stronghold_inside_img3_upload"
								ref={stronghold_inside_img3_upload}
								onChange={(e) => onChangePicture(e, 'stronghold_inside_img3')}
							/>
							<Input
								type="hidden"
								name="stronghold_inside_img3"
								defaultValue={formData.stronghold_inside_img3}
								ref={register({ required: true })}
							/>
							{formData.stronghold_inside_img3 ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.stronghold_inside_img3}
											alt="院區據點滑撥圖片3"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													stronghold_inside_img3: null,
												})

												stronghold_inside_img3_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.stronghold_inside_img3 && <FormHelperText>請指定一張圖片為院區據點滑撥圖片3</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<Heading as="h3" size="lg">
						門診時間表圖
					</Heading>
					<SimpleGrid columns={column} mt={5} spacingX={5} spacingY={5}>
						<FormControl>
							<FormLabel htmlFor="outpatient_time_web_img_upload" mb={1}>
								電腦版 <Badge ml={'20px'}>1920x1080(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="outpatient_time_web_img_upload"
								ref={outpatient_time_web_img_upload}
								onChange={(e) => onChangePicture(e, 'outpatient_time_web_img')}
							/>
							<Input
								type="hidden"
								name="outpatient_time_web_img"
								defaultValue={formData.outpatient_time_web_img}
								ref={register({ required: true })}
							/>
							{formData.outpatient_time_web_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.outpatient_time_web_img}
											alt="電腦版用圖"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													outpatient_time_web_img: null,
												})

												outpatient_time_web_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.outpatient_time_web_img && <FormHelperText>請指定一張圖片為電腦版用圖</FormHelperText>}
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="outpatient_time_mobile_img_upload" mb={1}>
								手機版 <Badge ml={'20px'}>1024x628(1 MB)</Badge>
							</FormLabel>
							<Input
								type="file"
								name="outpatient_time_mobile_img_upload"
								ref={outpatient_time_mobile_img_upload}
								onChange={(e) => onChangePicture(e, 'outpatient_time_mobile_img')}
							/>
							<Input
								type="hidden"
								name="outpatient_time_mobile_img"
								defaultValue={formData.outpatient_time_mobile_img}
								ref={register({ required: true })}
							/>
							{formData.outpatient_time_mobile_img ? (
								<HStack alignItems="flex-end">
									<Box w={'100%'}>
										<Image
											size="200px"
											objectFit="cover"
											src={REACT_APP_BASE_URL + formData.outpatient_time_mobile_img}
											alt="手機版"
										/>
									</Box>
									<Box>
										<Button
											colorScheme="brand"
											onClick={() => {
												setFormData({
													...formData,
													outpatient_time_mobile_img: null,
												})

												outpatient_time_mobile_img_upload.current.value = null
											}}
										>
											刪除圖片
										</Button>
									</Box>
								</HStack>
							) : null}
							{errors.outpatient_time_mobile_img && <FormHelperText>請指定一張圖片為手機版用圖</FormHelperText>}
						</FormControl>
					</SimpleGrid>
					<Divider my={10} />
					<Flex mt={4} pb={2} justify="flex-end">
						<Button colorScheme="brand" type="submit">
							<Box as={FaSave} mr={2} />
							{targetID ? '修改' : '新增'}
						</Button>
					</Flex>
				</form>
			</Box>
		</Flex>
	)
}

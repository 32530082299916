import React, { useContext } from 'react'
import context from 'context/dataProvider'
import { SurveyForm } from 'pages/SurveyPage/SurveyForm'

export const CustomForm = (props) => {
	const { type } = useContext(context)
	switch (type) {
		case 'Survey':
			return <SurveyForm data={props.data} />
		default:
			return
	}
}

import React, { useContext, useState, useEffect, Fragment } from 'react'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import {
	Box,
	HStack,
	Button,
	Input,
	IconButton,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react'
import { BsArrowRepeat } from 'react-icons/bs'
import DatePicker from 'react-datepicker'
import globalContext from 'context/globalContext'
import dataContext from 'context/dataProvider'
import 'react-datepicker/dist/react-datepicker.css'

const SurveyFeatures = () => {
	const { windowSize } = useContext(globalContext)
	const {
		originalData,
		setData,
		columns,
		setColumn,
		isFinished,
		setIsFinished,
		staffs,
		doctors,
		fetchSurvey,
		filteredRowsData,
		reacttablefilter,
		setReacttablefilter,
	} = useContext(dataContext)
	const [duration, setDuration] = useState({
		startDate: null,
		endDate: null,
	})

	const [alertIsOpen, setAlertIsOpen] = useState(false)
	const [exportData, setExportData] = useState([])
	const [exportHeader, setExportHeader] = useState([])
	const alertCancelRef = React.useRef()

	const handleSwitchColumn = (status) => {
		setIsFinished((prevState) => (status === 'finished' ? true : false))
	}

	const handleDatepickerOnChange = (type, date) => {
		if (type === 'start') {
			setDuration({ ...duration, startDate: date })
		} else {
			setDuration({ ...duration, endDate: date })
		}
	}

	const handleRefreshOnClick = () => {
		fetchSurvey()
		setDuration({
			startDate: null,
			endDate: null,
		})
	}

	const handleSearchOnClick = () => {
		handleFilterChange()
	}

	const handleFilterChange = () => {
		setReacttablefilter(!reacttablefilter)
		let filteredData = []

		const filters = () => {
			Object.assign(filteredData, originalData)

			const isFinishedFilter = () => {
				filteredData = originalData.filter((item) => {
					return item.status === (isFinished ? 1 : 0)
				})
			}

			const startDateFilter = () => {
				if (duration.startDate) {
					filteredData = filteredData.filter((item) => {
						return new Date(parseInt(item.client_complete_date) * 1000) >= duration.startDate
					})
				}
			}

			const endDateFilter = () => {
				if (duration.endDate) {
					filteredData = filteredData.filter((item) => {
						const tomorrow = new Date(duration.endDate)
						tomorrow.setDate(duration.endDate.getDate() + 1)
						return new Date(parseInt(item.client_complete_date) * 1000) < tomorrow
					})
				}
			}

			isFinishedFilter()
			startDateFilter()
			endDateFilter()
		}

		filters()

		setData((prevState) => {
			return filteredData
		})
	}

	const generateExportData = () => {
		let processedData = []
		let processedHeader = []

		filteredRowsData.map((item) => {
			let row = {}
			columns
				.filter((column) => column.accessor !== 'edit')
				.map((column) => {
					let value = item[column.accessor]
					let outputValue = ''

					switch (column.accessor) {
						case 'client_complete_date':
							if (value) outputValue = moment(value * 1000).format('yyyy-MM-DD')
							break
						case 'deal_date':
							if (value) outputValue = moment(value * 1000).format('yyyy-MM-DD')
							break
						case 'gender':
							outputValue = value === 0 ? '男' : '女'
							break
						case 'contact':
							outputValue = value === 0 ? '否' : '是'
							break
						case 'deal':
							outputValue = value === 0 ? '否' : '是'
							break
						case 'confirm':
							outputValue = value === 0 ? '否' : '是'
							break
						case 'contact_member':
							outputValue = value
							break
						case 'contact_doctor_id':
							outputValue = value
							break
						default:
							outputValue = value || ''
							break
					}
					return (row = { ...row, [column.accessor]: outputValue })
				})

			processedHeader.push()
			return processedData.push(row)
		})
		setExportData(processedData)
	}

	useEffect(() => {
		if (exportData.length) {
			let processedHeader = []
			const headerName = {
				id: '表單編號',
				client_complete_date: '客人填單日期',
				gender: '性別',
				items_id: '關注項目',
				live_location: '居住地區',
				advisory_location: '諮詢地區',
				name: '稱呼',
				phone: '手機號碼',
				phone2: '非台灣居民聯絡方式',
				email: 'Email',
				contact_time: '方便聯繫時間',
				duration: '落髮持續時間',
				client_notes: '客人備註',
				contact: '是否聯繫',
				deal: '成交確認',
				deal_date: '成交時間',
				deal_amount: '成交金額',
				confirm: '到店確認',
				contact_member: '聯繫者',
				location: '地區',
				contact_doctor_id: '聯絡醫師',
				invalid_reason: '無效原因',
				valid_reason_items_id: '有效原因',
				dcdc_notes: 'DCDC備註',
			}

			Object.keys(exportData[0]).forEach(function (key) {
				processedHeader.push({
					label: headerName[key],
					key,
				})
			})

			setExportHeader(processedHeader)
		}
	}, [exportData])

	useEffect(() => {
		if (filteredRowsData.length) generateExportData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredRowsData])

	useEffect(() => {
		if (staffs && doctors) {
			const unFinished = [
				{
					Header: '表單編號',
					accessor: 'id',
					width: 100,
					sticky: 'left',
				},
				{
					Header: '客人填單日期',
					accessor: 'client_complete_date',
					width: 140,
					Cell: ({ cell: { value } }) => {
						return value ? moment(value * 1000).format('yyyy-MM-DD') : null
					},
					sticky: 'left',
				},
				{
					Header: '性別',
					accessor: 'gender',
					width: 70,
					Cell: ({ cell: { value } }) => {
						switch (value) {
							case 0:
								return '男'
							case 1:
								return '女'
							default:
								return value
						}
					},
					sticky: 'left',
				},
				{
					Header: '關注項目',
					accessor: 'items_id',
					sticky: 'left',
				},
				{
					Header: '稱呼',
					accessor: 'name',
					width: 100,
					sticky: 'left',
				},
				{
					Header: '手機號碼',
					accessor: 'phone',
					width: 120,
					sticky: 'left',
				},
				{
					Header: '居住地區',
					accessor: 'live_location',
					width: 100,
				},
				{
					Header: '諮詢地區',
					accessor: 'advisory_location',
					width: 100,
				},
				{
					Header: '非台灣居民聯絡方式',
					accessor: 'phone2',
					width: 180,
				},
				{
					Header: 'Email',
					accessor: 'email',
					width: 200,
				},
				{
					Header: '方便聯繫時間',
					accessor: 'contact_time',
					wrap: true,
				},
				{ Header: '落髮持續時間', accessor: 'duration' },
				{
					Header: '客人備註',
					accessor: 'client_notes',
					wrap: true,
				},
				{
					Header: '是否聯繫',
					accessor: 'contact',
					Cell: ({ cell: { value } }) => {
						switch (value) {
							case 0:
								return '否'
							case 1:
								return '是'
							default:
								return value
						}
					},
				},
				{
					Header: 'DCDC備註',
					accessor: 'dcdc_notes',
					wrap: true,
				},
				{
					Header: '編輯',
					accessor: 'edit',
					disableSortBy: true,
					sticky: 'right',
				},
			]

			const finished = [
				{
					Header: '表單編號',
					accessor: 'id',
					width: 100,
					sticky: 'left',
				},
				{
					Header: '客人填單日期',
					accessor: 'client_complete_date',
					width: 140,
					Cell: ({ cell: { value } }) => {
						return value ? moment(value * 1000).format('yyyy-MM-DD') : null
					},
					sticky: 'left',
				},
				{
					Header: '性別',
					accessor: 'gender',
					width: 70,
					Cell: ({ cell: { value } }) => {
						return value === 0 ? '男' : '女'
					},
					sticky: 'left',
				},
				{
					Header: '關注項目',
					accessor: 'items_id',
					sticky: 'left',
				},
				{
					Header: '稱呼',
					accessor: 'name',
					width: 100,
					sticky: 'left',
				},
				{
					Header: '手機號碼',
					accessor: 'phone',
					width: 120,
					sticky: 'left',
				},
				{
					Header: '非台灣居民聯絡方式',
					accessor: 'phone2',
					width: 180,
				},
				{
					Header: 'Email',
					accessor: 'email',
					width: 200,
				},
				{
					Header: '成交確認',
					accessor: 'deal',
					Cell: ({ cell: { value } }) => {
						switch (value) {
							case 0:
								return '否'
							case 1:
								return '是'
							default:
								return value
						}
					},
				},
				{
					Header: '成交時間',
					accessor: 'deal_date',
					Cell: ({ cell: { value } }) => {
						return value ? moment(value * 1000).format('yyyy-MM-DD') : null
					},
				},
				{ Header: '成交金額', accessor: 'deal_amount' },
				{
					Header: '到店確認',
					accessor: 'confirm',
					Cell: ({ cell: { value } }) => {
						switch (value) {
							case 0:
								return '否'
							case 1:
								return '是'
							default:
								return value
						}
					},
				},
				{
					Header: '聯繫者',
					accessor: 'contact_member',
				},
				{ Header: '地區', accessor: 'location' },
				{
					Header: '聯絡醫師',
					accessor: 'contact_doctor_id',
				},
				{ Header: '無效原因', accessor: 'invalid_reason' },
				{
					Header: '有效原因',
					accessor: 'valid_reason_items_id',
					Cell: ({ cell: { value } }) => {
						switch (value) {
							case 0:
								return '植髮'
							case 1:
								return '髮際線'
							case 2:
								return '植鬍'
							case 3:
								return '植眉'
							case 4:
								return '落髮'
							case 5:
								return '其它'
							default:
								return ''
						}
					},
				},
				{
					Header: 'DCDC備註',
					accessor: 'dcdc_notes',
					wrap: true,
				},
				{
					Header: '編輯',
					accessor: 'edit',
					disableSortBy: true,
					sticky: 'right',
				},
			]

			setColumn((prevState) => (isFinished ? finished : unFinished))
			handleFilterChange()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFinished, originalData, staffs, doctors, setColumn])

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection={windowSize.width >= 1400 ? 'row' : 'column'}
			mb={2}
			position="relative"
			zIndex="5"
		>
			<HStack position="relative" minWidth="200px" mr={windowSize.width >= 1400 ? '15px' : '0'}>
				<HStack alignItems="center" flex="2" position="relative">
					<Box>
						<DatePicker
							selected={duration.startDate}
							onChange={(date) => handleDatepickerOnChange('start', date)}
							selectsStart
							maxDate={new Date()}
							startDate={duration.startDate}
							endDate={duration.endDate}
							isClearable
							placeholderText="起始時間"
							customInput={<Input bg="white" />}
						/>
					</Box>
					<Box>
						<DatePicker
							selected={duration.endDate}
							onChange={(date) => handleDatepickerOnChange('end', date)}
							selectsEnd
							startDate={duration.startDate}
							endDate={duration.endDate}
							minDate={duration.startDate}
							maxDate={new Date()}
							isClearable
							placeholderText="結束時間"
							customInput={<Input bg="white" />}
						/>
					</Box>
					<Box>
						<Button m={1} onClick={handleSearchOnClick}>
							搜尋
						</Button>
						<IconButton aria-label="Search database" icon={<BsArrowRepeat />} onClick={handleRefreshOnClick} />
						<AlertDialog
							isOpen={alertIsOpen}
							leastDestructiveRef={alertCancelRef}
							onClose={() => setAlertIsOpen(false)}
							isCentered
						>
							<AlertDialogOverlay />
							<AlertDialogContent borderRadius="10px">
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									日期區間有誤
								</AlertDialogHeader>

								<AlertDialogBody>請輸入完整日期區間</AlertDialogBody>

								<AlertDialogFooter>
									<Button
										leftIcon="check"
										colorScheme="brand"
										ref={alertCancelRef}
										onClick={() => setAlertIsOpen(false)}
									>
										我知道了
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</Box>
				</HStack>
			</HStack>
			<HStack flex="1" flexWrap="wrap" mr="0">
				<CSVLink data={exportData} headers={exportHeader} filename="exportedSurvey.csv" onClick={generateExportData}>
					<Button m={1}>匯出 Excel</Button>
				</CSVLink>
				{doctors && staffs ? (
					<Fragment>
						<Button
							colorScheme={!isFinished ? 'brand' : 'gray'}
							flex="1"
							m={1}
							onClick={() => handleSwitchColumn('unFinished')}
						>
							未完成表單
						</Button>
						<Button
							colorScheme={isFinished ? 'brand' : 'gray'}
							flex="1"
							m={1}
							onClick={() => handleSwitchColumn('finished')}
						>
							已完成表單
						</Button>
					</Fragment>
				) : null}
			</HStack>
		</Box>
	)
}

export default SurveyFeatures

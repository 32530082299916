import React, { useEffect, useState } from 'react'
import Table from 'shared/Table/Table'
import EditHairKnowledgeBanner from 'pages/HairKnowledgePage/EditHairKnowledgeBanner'
import { Provider } from 'context/dataProvider'
import { getKnowledgeBannerList } from 'api/Knowledge'

export const HairKnowledgeBanner = () => {
  const [data, setData] = useState([])
  const [type] = useState('Knowledge-Banner')
  const [columns] = useState([
    {
      Header: 'No',
      accessor: 'no',
    },
    {
      Header: '類別',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return knowledgeCategory.filter((item) => item.id === value).map((data) => data.name)
      },
    },
    {
      Header: '編輯',
      accessor: 'edit',
      disableSortBy: true,
    },
  ])

  const knowledgeCategory = [
    { id: 17, name: '生髮知識首頁' },
    { id: 18, name: '植髮案例' },
    { id: 20, name: '植髮觀念' },
    { id: 21, name: '頭皮養護' },
    { id: 22, name: '落髮判斷' },
    { id: 36, name: '無針擬真繡髮' },
  ]

  const fetchData = () => {
    getKnowledgeBannerList()
      .then(function (res) {
        setData(res.data.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const context = {
    data,
    setData,
    columns,
    type,
  }

  return (
    <Provider value={context}>
      {data.length < 6 ? <EditHairKnowledgeBanner/>: null}
      <Table />
    </Provider>
  )
}
